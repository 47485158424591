export const binarySearch = (arr, value) => {
  if (arr.length === 0) return -1
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midName = arr[mid];

    if (midName === value) {
      return mid
    } else if (midName < value) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }

  return -1
}