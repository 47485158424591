import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import useSearchParamsHandler from "./useSearchParamsHandler";
import {
  allPagesAtom,
  allProductsAtom,
  currentPageAtom,
  fetchProductsAtom,
  productPerPageAtom,
  productsInfoAtom,
  productsLoadingStatusAtom,
} from "../atom/products";
import { useAtom } from "jotai";

const useUpdateProducts = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { params } = useSearchParamsHandler();
  const [, fetchProducts] = useAtom(fetchProductsAtom);
  const [productsLoadingStatus, setProductsLoadingStatus] = useAtom(
    productsLoadingStatusAtom,
  );
  const [productsInfo] = useAtom(productsInfoAtom);
  const [currentPage] = useAtom(currentPageAtom);
  const [allPages] = useAtom(allPagesAtom);
  const [productPerPage] = useAtom(productPerPageAtom);
  const [allProducts] = useAtom(allProductsAtom);

  const settingProducts = useCallback(async (location) => {
    try {
      setProductsLoadingStatus("loading");
      await fetchProducts(location);
    } catch (e) {
      console.error(e);
    } finally {
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!location.search.includes(`page=${currentPage}`)) {
      setSearchParams({ ...params, page: currentPage });
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);

    settingProducts(
      location.search.startsWith("?")
        ? location.search.slice(1)
        : location.search,
    );

    return;
    // eslint-disable-next-line
  }, [searchParams]);

  const data = useMemo(
    () => ({
      currentPage,
      allPages,
      productsInfo,
      productPerPage,
      allProducts,
      productsLoadingStatus,
    }),
    [
      currentPage,
      allPages,
      productsInfo,
      productPerPage,
      allProducts,
      productsLoadingStatus,
    ],
  );

  return data;
};

export default useUpdateProducts;
