import { Tag } from "@chakra-ui/react";
import { getCurrentColor } from "../constants";

export const getProductCharacterictic = (characteristics, colorMode) => {
  return characteristics.map(
    (characteristic, index) =>
      characteristic.length > 0 && (
        <Tag
          borderRadius={"16px"}
          fontSize={"13px"}
          key={characteristic + index}
          color={getCurrentColor(colorMode, "blackC.300", "grayC.1600")}
          bg={getCurrentColor(colorMode, "grayC.400", "grayC.1900")}
        >
          {characteristic}
        </Tag>
      ),
  );
};
