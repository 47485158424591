import { Box, Flex, Skeleton } from "@chakra-ui/react";

const FilterSkeletonDesktop = () => {
  return (
    <>
      <Skeleton height={"25px"} mb={"15px"} width={"50%"} />
      <Skeleton height={"40px"} mb={"35px"} />
      <Box pb={5}>
        <Skeleton height={"25px"} mb={"15px"} width={"50%"} />
        <Skeleton height={"40px"} mb={"35px"} />
      </Box>
      <Box>
        <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
        <Skeleton height={"40px"} mb={"45px"} />
        <Flex flexDirection={"column"} gap={"10px"}>
          <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
          <Skeleton height={"250px"} mb={"5px"} />
          <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
          <Skeleton height={"250px"} mb={"5px"} />
          <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
          <Skeleton height={"250px"} mb={"5px"} />
          <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
          <Skeleton height={"250px"} mb={"5px"} />
          <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
          <Skeleton height={"250px"} mb={"5px"} />
        </Flex>
      </Box>
    </>
  );
};

export default FilterSkeletonDesktop;
