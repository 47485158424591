import { useCallback, useEffect, useMemo, useState } from "react";
import useHttp from "./useHttp";
import { useLocation, useSearchParams } from "react-router-dom";
import useSearchParamsHandler from "./useSearchParamsHandler";

const useSearchProduct = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { params } = useSearchParamsHandler();
  const getCurrentPage = searchParams.get("page");

  const { getSearch } = useHttp();
  const [currentPage, setCurrentPage] = useState(getCurrentPage || 1);
  const [allPages, setAllPages] = useState(1);
  const [products, setProducts] = useState([]);
  const [productPerPage, setProductPerPage] = useState(0);
  const [allProducts, setAllProducts] = useState(0);

  const [loading, setLoading] = useState(true);

  const settingProducts = useCallback(async (location) => {
    try {
      setLoading(true);
      const request = await getSearch(location);

      setProductPerPage(await request.response.per_page);
      setProducts(await request.response.data);
      setCurrentPage(await request.response.current_page);
      setAllPages(
        await Math.ceil(+request.response.total / +request.response.per_page),
      );
      setAllProducts(await request.response.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!location.search.includes(`page=${currentPage}`)) {
      setSearchParams({ ...params, page: currentPage });
    }

    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    settingProducts(
      location.search.startsWith("?")
        ? location.search.slice(1)
        : location.search,
    );

    // eslint-disable-next-line
  }, [location.search]);

  const data = useMemo(
    () => ({
      currentPage,
      allPages,
      products,
      productPerPage,
      allProducts,
      setCurrentPage,
      loading,
    }),
    [currentPage, allPages, products, productPerPage, allProducts, loading],
  );

  return data;
};

export default useSearchProduct;
