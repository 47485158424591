import {
  Box,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Text,
  Link,
  useColorMode,
  Tooltip,
} from "@chakra-ui/react";
import ToolTipIssue from "../ToolTip/ToolTipIssue";
import { FlagIcon } from "../../assets/icons/icons";
import ToolTipTotal from "../ToolTip/ToolTipTotal";
import Rating from "../Rating/Rating";
import { getProductCharacterictic } from "../../utils/getProductCharacterictic";
import { getCurrentColor } from "../../constants";
import { cautionStatus } from "../../utils/cautionStatus";

const ProductItemMobile = ({ productData, recomendedBorder }) => {
  const { colorMode } = useColorMode();
  const {
    price,
    cpr,
    landed_price,
    landed_cpr,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
    title,
    condition,
    vendor_name,
    vendor_rating,
    ship_flag,
    vendor_rating_count,
    affiliate_url,
    recommend_flag,
    brand_image_w,
    brand_image_b,
    gb_flag,
    ship_cost,
    taxes,
    shell_length,
    weapon_category,
  } = productData;

  const characteristics = [
    weapon_category === "Shotgun" && shell_length,
    condition,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
  ];

  return (
    <ListItem
      border={recommend_flag === "1" ? "2px solid" : "1px solid"}
      borderColor={
        recommend_flag === "1"
          ? "blueC.100"
          : getCurrentColor(colorMode, "grayC.500", "grayC.2400")
      }
      borderRadius={"5px"}
      pt={recommend_flag === "1" ? "45px" : "17px"}
      pb={3}
      px={5}
      gap={{ base: 0, md: 42 }}
      position={"relative"}
      display={{ base: "flex", md: "none" }}
      maxW={{ base: "320px", "1xs": "378px", lg: "350px" }}
      w={"100%"}
      boxShadow={
        "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
      }
      flexDirection={"column"}
      bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
    >
      {recommend_flag === "1" && recomendedBorder()}
      <Flex justifyContent={"space-between"} mb={"12px"} alignItems={"center"}>
        <Image
          src={getCurrentColor(colorMode, brand_image_w, brand_image_b)}
          w={"90px"}
          loading="lazy"
          alt={title}
        />

        <ToolTipIssue>
          <Box
            cursor={"auto"}
            border={"1px solid"}
            display={"flex"}
            bottom={"10px"}
            right={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            borderColor={"grayC.500"}
            borderRadius={"50%"}
            w={"20px"}
            h={"20px"}
          >
            <FlagIcon
              w={"10px"}
              h={"10px"}
              color={getCurrentColor(colorMode, "grayC.2700", "grayC.1600")}
            />
          </Box>
        </ToolTipIssue>
      </Flex>
      <Flex flexDirection={"column"} w={"100%"}>
        <Flex w={"100%"} justifyContent={"space-between"} gap={14} mb={"12px"}>
          <List
            display={"flex"}
            w={"100%"}
            gap={3}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <ListItem width={"100%"}>
              <Heading
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={{ base: "sm", sm: "lg" }}
                fontWeight={"600"}
                fontFamily={"mainFont"}
              >
                {title}
              </Heading>
            </ListItem>

            <ListItem>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Rounds
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"sm"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                {rdsperbox}
              </Heading>
            </ListItem>

            <ListItem>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Item Cost
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"sm"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                ${(+cpr).toFixed(2)}{" "}
                <Text as={"span"} ml={"5px"}>
                  CPR
                </Text>
              </Heading>
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"sm"}
                fontWeight={"400"}
                fontFamily={"mainFont"}
              >
                ${+price}
              </Text>
            </ListItem>

            <ListItem>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Total Cost
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blueC.200", "whiteC.100")}
                textDecor={"underline"}
                fontSize={"md "}
                fontWeight={"700"}
                fontFamily={"mainFont"}
                display={"flex"}
                gap={"6px"}
                alignItems={"center"}
                cursor={"pointer"}
              >
                <ToolTipTotal
                  totalCost={landed_price}
                  ship_cost={ship_cost}
                  status={ship_flag}
                  price={price}
                  taxes={taxes}
                >
                  $
                  {+landed_cpr % 1 === 0
                    ? (+landed_cpr).toFixed(2)
                    : +landed_cpr}{" "}
                  <Text as={"span"} mr={"5px"}>
                    CPR
                  </Text>
                  <Box as={"span"} cursor={"pointer"}>
                    {cautionStatus(ship_flag).icon}
                  </Box>
                </ToolTipTotal>
              </Heading>
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"sm"}
                fontWeight={"400"}
                fontFamily={"mainFont"}
              >
                ${(+landed_price).toFixed(2)}
              </Text>
            </ListItem>
          </List>
        </Flex>
        <Flex gap={"8px"} flexWrap={"wrap"} mb={5}>
          {getProductCharacterictic(characteristics, colorMode)}
        </Flex>
      </Flex>
      <Flex justifyContent={"space-between"} gap={2} mt={"auto"}>
        <Flex flexDirection={"column"}>
          <Flex mb={"5px"} flexDirection={"column"}>
            {vendor_name.length > 15 ? (
              <Tooltip
                cursor={"pointer"}
                hasArrow
                label={vendor_name}
                bg="gray.300"
                color="black"
              >
                {vendor_name.length > 15
                  ? `${vendor_name.slice(0, 14)}...`
                  : vendor_name}
              </Tooltip>
            ) : (
              <Text
                fontSize={"13px"}
                mb={"5px"}
                fontFamily={"mainFont"}
                as={"b"}
              >
                {vendor_name}
              </Text>
            )}
          </Flex>
          {gb_flag === 1 && (
            <Flex gap={"5px"} mb={"7px"}>
              <Image
                w={"34px"}
                loading="lazy"
                src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
                alt="gun broker"
              />
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"xs"}
                fontFamily={"mainFont"}
              >
                via GunBroker
              </Text>
            </Flex>
          )}
          <Rating rating={vendor_rating} reviews={vendor_rating_count} />
        </Flex>

        <Link
          href={`${affiliate_url}`}
          px={"18px"}
          py={"10px"}
          bg={"blueC.100"}
          target="_blank"
          _hover={{ bg: "blueC.200" }}
          color={"whiteC.100"}
          borderRadius={"5px"}
          w={"125px"}
          h={"40px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={"auto"}
        >
          View
        </Link>
      </Flex>
    </ListItem>
  );
};

export default ProductItemMobile;
