import { Box, Text } from "@chakra-ui/react";
import { TriangleRecommendedIcon } from "../../assets/icons/icons";

import ProductItemDesktop from "./ProductItemDesktop";
import ProductItemMobile from "./ProductItemMobile";
const ProductItem = ({ product }) => {
  const renderRecomended = () => {
    return (
      <Box
        position={"absolute"}
        top={0}
        left={0}
        borderRadius={"0px 0px 5px 0px"}
      >
        <TriangleRecommendedIcon
          position={"relative"}
          top={"-1px"}
          left={"-1px"}
          zIndex={99}
        />
        <Text
          color={"whiteC.100"}
          position={"absolute"}
          top={"50%"}
          left={"20px"}
          transform={"translateY(-50%)"}
          zIndex={99}
          fontSize={"15px"}
          fontWeight={"600"}
        >
          Recommended
        </Text>
      </Box>
    );
  };
  return (
    <>
      {/* desktop */}
      <ProductItemDesktop
        recomendedBorder={renderRecomended}
        productData={product}
      />
      {/* Mobile */}
      <ProductItemMobile
        recomendedBorder={renderRecomended}
        productData={product}
      />
    </>
  );
};

export default ProductItem;
