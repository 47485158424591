import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_X_API_KEY,
  },
});

export default apiClient;
