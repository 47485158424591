import apiClient from "../utils/apiClient";

const useHttp = () => {
  const getProducts = async (query = "") => {
    try {
      const response = await apiClient.get(`listings?${query}`);
      return response.data;
    } catch (error) {
      console.error("Error getting products: ", error);
      throw error;
    }
  };

  const getCalibers = async () => {
    try {
      const response = await apiClient.get(`/category_calibers`);

      return response.data.response;
    } catch (error) {
      console.error("Error getting filters: ", error);
      throw error;
    }
  };

  const getSearch = async (query = "") => {
    try {
      const response = await apiClient.get(`search${query}`);

      return response.data;
    } catch (error) {
      console.error("Error getting products: ", error);
      throw error;
    }
  };

  const postMessage = async (data) => {
    try {
      const response = await apiClient.post("/Emails/contact", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return new Promise((resolve) => resolve(response.data));
    } catch (error) {
      console.error("Error getting products: ", error);
      return new Promise((reject) => reject(error));
    }
  };

  const postProductReport = async (data) => {
    try {
      const response = await apiClient.post("/Emails/report", data);
      return response.data;
    } catch (error) {
      console.error("Error getting products: ", error);
      throw error;
    }
  };

  return {
    getProducts,
    getCalibers,
    getSearch,
    postMessage,
    postProductReport,
  };
};

export default useHttp;
