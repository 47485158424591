import { SearchIcon } from "@chakra-ui/icons";
import { Box, Input, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";
import { memo } from "react";

const SearchInput = memo(
  ({ value, handleChange, display, name, icon = true, ...props }) => {
    const { colorMode } = useColorMode();
    return (
      <Box position={"relative"} w={"100%"}>
        {icon && (
          <SearchIcon
            color={getCurrentColor(colorMode, "grayC.1900", "grayC.1600")}
            position={"absolute"}
            top={"50%"}
            left={"12px"}
            transform={"translateY(-50%)"}
            display={display}
            zIndex={"98"}
          />
        )}
        <Input
          w={"100%"}
          pl={{ base: 3, md: "44px" }}
          py={"11px "}
          pr={3}
          placeholder="Search"
          bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
          color={getCurrentColor(colorMode, "grayC.1900", "grayC.1600")}
          border={"none"}
          outline={"none"}
          focusBorderColor={getCurrentColor(colorMode, "midnightC.400", "none")}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          name={name}
          id={name}
          {...props}
        />
      </Box>
    );
  },
);

export default SearchInput;
