import {
  Flex,
  Grid,
  Heading,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { getCurrentColor } from "../../constants";

const data = [
  {
    title: "Featured Brands",
    description: "Discover premium ammunition from industry-leading brands.",
    logos: [
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/1.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/2.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/3.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/4.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/5.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/6.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/7.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/8.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/9.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/10.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/11.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/brands/12.webp",
        black: "",
      },
    ],
  },
  {
    title: "Featured Vendors",
    description:
      "Shop with confidence from trusted ammunition vendors and suppliers.",
    logos: [
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/1.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/2.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/3.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/4.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/5.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/6.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/7.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/8.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/9.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/10.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/11.webp",
        black: "",
      },
      {
        white: "https://cdn.bulletscout.com/image/main-page/vendors/12.webp",
        black: "",
      },
    ],
  },
];

const MainPageSectionBrands = () => {
  const { colorMode } = useColorMode();
  return data.map((items) => {
    return (
      <Flex
        flexDirection={"column"}
        maxW={"1240px"}
        w={"100%"}
        key={items.title}
        mb={{ base: "50px", lg: "60px" }}
        mx={"auto"}
        px={{ base: "22px", ".5xl": "0px" }}
      >
        <Heading
          mb={2}
          fontFamily={"mainFont"}
          fontSize={{ base: "25px", md: "3xl" }}
          color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
        >
          {items.title}
        </Heading>
        <Text
          mb={{ base: 6, md: "30px" }}
          fontFamily={"mainFont"}
          fontSize={"lg"}
          color={getCurrentColor(colorMode, "grayC.100", "grayC.1600")}
        >
          {items.description}
        </Text>
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            xl: "repeat(4, 1fr)",
            ".5xl": "repeat(6, 1fr)",
          }}
          gap={"10px"}
          mx={"auto"}
        >
          {items.logos.map((logo, i) => (
            <Flex
              justifyContent={"center"}
              key={i}
              alignItems={"center"}
              w={{ base: "150px", "1xs": "185px", lg: "198px" }}
              h={{ base: "76px", lg: "82px" }}
              borderRadius={"5px"}
              border={"1px solid "}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.900",
                "grayC.2400",
              )}
              boxShadow={
                "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
              }
              bg={getCurrentColor(colorMode, "whiteC.100", "transparent")}
            >
              <Image w={"auto"} h={"auto"} loading="lazy" src={logo.white} alt={logo.white} />
            </Flex>
          ))}
        </Grid>
      </Flex>
    );
  });
};

export default MainPageSectionBrands;
