import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import useCognitoUserPool from "./useCognitoUserPool";
import { useAtom } from "jotai";
import { userInfoAtom } from "../atom/user";

const useLogout = () => {
  const { userPool } = useCognitoUserPool();

  const [, setUserId] = useAtom(userInfoAtom);

  const navigate = useNavigate();

  const logout = (username) => {
    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.signOut();
    setUserId(null);
    navigate("/");
    navigate(0);
  };

  return { logout };
};

export default useLogout;
