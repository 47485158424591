import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Link,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import MobileMenuWrapper from "../../MobileMenuWrapper/MobileMenuWrapper";
import SearchInput from "../../SearchInput/SearchInput";
import {
  AllBoosIcon,
  HandgunIcon,
  RifleIcon,
  RimfireIcon,
  ShotgunIcon,
} from "../../../assets/icons/icons";
import useUpdateQuickNavigate from "../../../hooks/useUpdateQuickNavigate";
import { getCurrentColor } from "../../../constants";

const categories = [
  { name: "Handgun", icon: <HandgunIcon /> },
  { name: "Rifle", icon: <RifleIcon /> },
  { name: "Rimfire", icon: <RimfireIcon /> },
  { name: "Shotgun", icon: <ShotgunIcon /> },
  { name: "All categories", icon: <AllBoosIcon /> },
];

const QuickNavigateMobile = ({ showNavigate, handleChangeStatus }) => {
  const { colorMode } = useColorMode();
  const { filteredCalibers, loading } = useUpdateQuickNavigate("");

  const RenderAccortionItem = ({ category, calibersData }) => {
    const [search, setSearch] = useState("");
    const [filteredCalibersNew, setFilteredCalibers] = useState(calibersData);

    const { name, icon } = category;

    useEffect(() => {
      sortCalibers(search);
      // eslint-disable-next-line
    }, [search]);

    const sortCalibers = (search) => {
      if (search.length === 0) {
        setFilteredCalibers(calibersData);
      } else {
        const filteredCalibers = calibersData.calibers.filter((caliber) =>
          caliber.caliber.toLowerCase().includes(search.toLowerCase()),
        );

        const filteredData = {
          ...calibersData,
          calibers: filteredCalibers,
        };

        setFilteredCalibers(filteredData);
      }
    };

    return (
      <AccordionItem key={name} border={"none"}>
        <AccordionButton
          bg={getCurrentColor(colorMode, "grayC.600", "grayC.1700")}
          _hover={{ bg: getCurrentColor(colorMode, "grayC.600", "grayC.1700") }}
        >
          <Flex
            gap={"18px"}
            as="span"
            flex="1"
            alignItems={"center"}
            textAlign="left"
          >
            {icon}
            {name}
          </Flex>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel
          pb={4}
          pt={"18px"}
          overflow={"auto"}
          maxH={"400px"}
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1900")}
        >
          <SearchInput
            value={search}
            handleChange={setSearch}
            display={"block"}
            pl={"42px"}
            border={"1px solid"}
            borderColor={"grayC.500"}
          />

          <Flex flexDirection={"column"}>
            <Heading
              mt={"18px"}
              fontSize={"sm"}
              color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
              fontFamily={"mainFont"}
              fontWeight={"600"}
              mb={"18px"}
            >
              All Calibers
            </Heading>

            <Flex flexDirection={"column"} gap={"14px"}>
              {!loading && filteredCalibersNew
                ? filteredCalibersNew.calibers &&
                  filteredCalibersNew.calibers.length > 0
                  ? filteredCalibersNew.calibers.map((item, index) => {
                      return (
                        <Link
                          href={`/${name.toLowerCase()}-ammo/${encodeURIComponent(item.caliber.toLowerCase())}?wc=${category.name.replace(" Ammo", "").toLowerCase()}&caliber=${encodeURIComponent(item.caliber)}&sort_by=landed_cpr&page=1`}
                          display={"flex"}
                          justifyContent={"space-between"}
                          key={item + index}
                          color={getCurrentColor(
                            colorMode,
                            "grayC.200",
                            "grayC.1600",
                          )}
                        >
                          <Text
                            fontSize={"sm"}
                            color={getCurrentColor(
                              colorMode,
                              "grayC.200",
                              "grayC.1600",
                            )}
                            fontFamily={"mainFont"}
                            fontWeight={"500"}
                          >
                            {item.caliber}
                          </Text>
                          <Text
                            fontSize={"13"}
                            color={getCurrentColor(
                              colorMode,
                              "grayC.200",
                              "grayC.1600",
                            )}
                            fontFamily={"mainFont"}
                            fontWeight={"400"}
                          >
                            {item.total_count}
                          </Text>
                        </Link>
                      );
                    })
                  : "Calibers not found"
                : "Loading..."}
            </Flex>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    );
  };

  return (
    <MobileMenuWrapper
      openStatus={showNavigate}
      handleChangeStatus={handleChangeStatus}
      title={"Quick Navigate"}
    >
      <Accordion allowMultiple>
        {categories.map((category, i) => (
          <RenderAccortionItem
            category={category}
            calibersData={filteredCalibers[i]}
            key={category.name}
          />
        ))}
      </Accordion>
    </MobileMenuWrapper>
  );
};

export default QuickNavigateMobile;
