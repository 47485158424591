import { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import useCognitoUserPool from "./useCognitoUserPool";

const useResendConfirmationCode = () => {
  const [manyResends, setManyResends] = useState(false);
  const { userPool } = useCognitoUserPool();

  const resendingConfirmationCode = (username) => {
    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        if (
          err.message === "Attempt limit exceeded, please try after some time."
        ) {
          setManyResends(true);
        }
        return;
      }
    });
  };

  return { manyResends, resendingConfirmationCode };
};

export default useResendConfirmationCode;
