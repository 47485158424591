import { CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

const getUserFromLocalStorage = (rememberMe) => {
  const poolData = {
    UserPoolId: process.env.REACT_APP_USERPOOL_ID,
    ClientId: process.env.REACT_APP_APPCLIENT_ID,
  };

  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("User not found in local storage."));
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }

      if (!session.isValid()) {
        reject(
          new Error("The session is not valid. You need to log in again."),
        );

        return;
      }

      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: poolData.UserPoolId,
        Logins: {
          [`cognito-idp.us-east-1.amazonaws.com/${poolData.UserPoolId}`]:
            session.getIdToken().getJwtToken(),
        },
      });
    });
  });
};

export default getUserFromLocalStorage;
