

export const validateNewPassword = (newPassword) => {
  if (!/[A-Z]/.test(newPassword)) {
    return 'Password must contain at least one uppercase letter'
  } else if (!/[a-z]/.test(newPassword)) {
    return 'Password must contain at least one lowercase letter'
  } else if (!/\d/.test(newPassword)) {
    return 'Password must contain at least one number'
  } else if (!/[\W_]/.test(newPassword)) {
    return 'Password must contain at least one special character'
  } else if (newPassword.length < 8) {
    return 'Password must be at least 8 characters long'

  } else {
    return false
  }
}