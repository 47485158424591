import { transformDataWithId } from "./transformDataWithId";

export const selectedFiltersFromQueryParams = (queryString, filterInfo) => {
  const filtersData = queryString.filter(
    (item) =>
      item.title.length > 0 &&
      item.value.length > 0 &&
      !item.title.startsWith("wc") &&
      !item.title.startsWith("sort_by") &&
      !item.title.startsWith("caliber") &&
      !item.title.startsWith("page"),
  );

  const processFiltersData = (filtersData, prefix) => {
    return filtersData
      .filter((item) => item.title.startsWith(prefix))
      .flatMap((item) =>
        item.value.split(",").map((val) => ({
          title: item.title,
          value: val.trim(),
        })),
      );
  };

  const sf = processFiltersData(filtersData, "sf");
  const bullet_type_s = processFiltersData(filtersData, "bullet_type_s");
  const bt = processFiltersData(filtersData, "bt");
  const shell_length = processFiltersData(filtersData, "shell_length");
  const mt = processFiltersData(filtersData, "mt");
  const rpb = processFiltersData(filtersData, "rpb");

  return (
    filterInfo && {
      sf,
      bullet_type_s,
      bt,
      shell_length,
      mt,
      rpb,
      bw: transformDataWithId(
        filterInfo,
        filtersData.filter((item) => item.title.startsWith("bw")),
        "bullet_weight",
        "name",
        "bw",
        "alt_name",
      ),
      brnd: transformDataWithId(
        filterInfo,
        filtersData.filter((item) => item.title.startsWith("brnd")),
        "brand",
        "brand_name",
        "brnd",
        "brand_id",
      ),
      vid: transformDataWithId(
        filterInfo,
        filtersData.filter((item) => item.title.startsWith("vid")),
        "vendor_name",
        "vendor_name",
        "vid",
        "vendor_id",
      ),
    }
  );
};
