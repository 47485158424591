import { ArrowForwardIcon } from "@chakra-ui/icons";
import { GridItem, Image, Link, Text, useColorMode } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { calibersLink, getCurrentColor } from "../../constants";
import { Helmet } from "react-helmet";
import useCookie from "../../hooks/useCookie";

const CaliberItem = ({ category, path, categoryName }) => {
  const { caliber, total_count, caliber_image } = category;
  const { colorMode } = useColorMode();
  const {cookies} = useCookie();

  return (
    <GridItem
      w={{ base: "150px", "1xs": "185px", md: "190px" }}
      h={{ base: "150px", "1xs": "185px", md: "190px" }}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      border={"1px solid "}
      borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.2400")}
      bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
      pt={{ base: "14px", md: "15px" }}
      borderRadius={"5px"}
    >
      <Helmet>
        <link rel="preconnect" as="image" href={caliber_image} />
      </Helmet>

      <Image
        src={caliber_image}
        w={{ base: "70px", "1xs": "116px", md: "120px" }}
        h={{ base: "60px", "1xs": "86px", md: "90px" }}
        alt={`${caliber} ammo`}
        mb={"8px"}
        objectFit={"cover"}
      />

      <Text
        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
        fontFamily={"mainFont"}
        fontWeight={"600"}
        fontSize={"sm"}
        mb={"auto"}
      >
        {caliber}
      </Text>
      <Link
        as={RouterLink}
        py={"10px"}
        display={"flex"}
        w={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        borderBottomRadius={"5px"}
        to={calibersLink(path, caliber, categoryName, cookies.zip)}
        bg={"blueC.100"}
        _hover={{ bg: "blueC.200" }}
        color={"whiteC.100"}
        fontSize={"xs"}
      >
        {total_count} Products <ArrowForwardIcon color={"whiteC.100"} />
      </Link>
    </GridItem>
  );
};

export default CaliberItem;
