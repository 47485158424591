export const transformFilterName = (location, query, filter) => {
  switch (query) {
    case "sf":
      return {
        title: "Shipping",
        query: "sf",
        value: filter.value.map((item) =>
          item === "1" ? "Verified" : item === "2" ? "Caution" : "Unknown",
        ),
        flag: filter.value.map((item) => item),
      };
    case "bw":
      return {
        title: "Bullet Weight",
        query: "bw",
        value: filter.value,
        flag: filter.flag,
      };
    case "rpb":
      return {
        title: "Rounds",
        query: "rpb",
        value: filter.value,
        flag: filter.flag,
      };
    case "bt":
      return {
        title: "Bullet Type",
        query: "bt",
        value: filter.value,
        flag: filter.flag,
      };
    case "mt":
      return {
        title: "Material",
        query: "mt",
        value: filter.value,
        flag: filter.flag,
      };
    case "brnd":
      return {
        title: "Brand",
        query: "brnd",
        value: filter.value,
        flag: filter.flag,
      };
    case "vid":
      return {
        title: "Vendor",
        query: "vid",
        value: filter.value,
        flag: filter.flag,
      };
    case "bullet_type_s":
      return {
        title: location.includes("shotgun" || "Shotgun")
          ? "Shot size"
          : "Bullet type",
        query: "bullet_type_s",
        value: filter.value,
        flag: filter.flag,
      };
    case "shell_length":
      return {
        title: "Shell Length",
        query: "shell_length",
        value: filter.value,
        flag: filter.flag,
      };
    default:
      return "";
  }
};
