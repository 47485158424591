import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  FormErrorMessage,
  FormControl,
  useColorMode,
} from "@chakra-ui/react";

import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { emailReg, getCurrentColor, passwordValidate } from "../constants";
import { Helmet } from "react-helmet";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID,
  ClientId: process.env.REACT_APP_APPCLIENT_ID,
});

const Singup = () => {
  const { colorMode } = useColorMode();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = (values) => {
    const trimmedValues = {
      ...values,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.trim(),
      password: values.password.trim(),
      confirm_pass: values.confirm_pass.trim(),
    };

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: trimmedValues.email,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: trimmedValues.first_name,
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: trimmedValues.last_name,
      }),
    ];

    userPool.signUp(
      trimmedValues.email,
      trimmedValues.password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          setError(err.message || JSON.stringify(err));
          return;
        }
        navigate(`/confirm-email/${result.userSub}`);
      },
    );
  };

  return (
    <Flex
      bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
      pt={{ base: "28px", md: "37px" }}
      pb={{ base: "129px", md: "200px" }}
      px={{ base: "20px", md: "10px" }}
    >
      <Helmet>
        <title>Sing up to Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <Box
        maxW={"362px"}
        w={"100%"}
        mx={"auto"}
        flexDirection={"column"}
        pt={{ base: "28px", md: "70px" }}
      >
        <Heading
          as={"h2"}
          fontFamily={"mainFont"}
          fontSize={"2xl"}
          mb={{ base: "32px", md: "26px" }}
        >
          Create an account
        </Heading>

        {error && (
          <Text color="red.500" mb={"18px"}>
            {error}
          </Text>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={4} mb={"25px"}>
            <FormControl isInvalid={errors.first_name} position={"relative"}>
              <Input
                placeholder="First name"
                border={"0.5px solid"}
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.1400",
                  "grayC.2200",
                )}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
                }}
                focusBorderColor={"grayC.1300"}
                id="first_name"
                name="first_name"
                {...register("first_name", {
                  required: "This is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length should be 2",
                  },
                })}
              />
              <FormErrorMessage
                position={"absolute"}
                bottom={-4}
                left={0}
                fontSize={"12px"}
              >
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.last_name} position={"relative"}>
              <Input
                placeholder="Last name"
                border={"0.5px solid"}
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.1400",
                  "grayC.2200",
                )}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
                }}
                focusBorderColor={"grayC.1300"}
                id="last_name"
                name="last_name"
                {...register("last_name", {
                  required: "This is required",
                  minLength: {
                    value: 1,
                    message: "Minimum length should be 2",
                  },
                })}
              />

              <FormErrorMessage
                position={"absolute"}
                bottom={-4}
                left={0}
                fontSize={"12px"}
              >
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl isInvalid={errors.email} position={"relative"}>
            <Input
              placeholder="Email address"
              border={"0.5px solid"}
              bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.1400",
                "grayC.2200",
              )}
              _placeholder={{
                color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
              }}
              focusBorderColor={"grayC.1300"}
              mb={"25px"}
              id="email"
              name="email"
              {...register("email", {
                required: "This is required",
                pattern: {
                  value: emailReg,
                  message: "Invalid email address",
                },
              })}
            />

            <FormErrorMessage
              position={"absolute"}
              bottom={2}
              left={0}
              fontSize={"12px"}
            >
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password} position={"relative"}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                mb={"25px"}
                placeholder="Enter password"
                border={"0.5px solid"}
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.1400",
                  "grayC.2200",
                )}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
                }}
                focusBorderColor={"grayC.1300"}
                id="password"
                name="password"
                {...register("password", {
                  required: "This is required",
                  minLength: {
                    value: 8,
                    message: "Minimum length should be 8",
                  },
                  validate: passwordValidate,
                })}
              />
              <InputRightElement width="4.5rem">
                <Button
                  bg={"transparent"}
                  border={"none"}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                  _hover={{ bg: "transparent" }}
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage
              position={"absolute"}
              bottom={2}
              left={0}
              fontSize={"12px"}
            >
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.confirm_pass} position={"relative"}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Confirm password"
                border={"0.5px solid"}
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.1400",
                  "grayC.2200",
                )}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
                }}
                focusBorderColor={"grayC.1300"}
                id="confirm_pass"
                mb={"32px"}
                name="confirm_pass"
                {...register("confirm_pass", {
                  validate: {
                    matchesPassword: (value) =>
                      value === watch("password") || "Passwords don't match",
                    minLength: (value) => {
                      if (!value || value.length >= 8) {
                        return true;
                      }
                      return "Minimum length should be 8";
                    },
                    required: (value) => !!value || "This is required",
                  },
                })}
              />
              <InputRightElement width="4.5rem">
                <Button
                  bg={"transparent"}
                  border={"none"}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  _hover={{ bg: "transparent" }}
                >
                  {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage
              position={"absolute"}
              bottom={3}
              left={0}
              fontSize={"12px"}
            >
              {errors.confirm_pass && errors.confirm_pass.message}
            </FormErrorMessage>
          </FormControl>

          <Flex mb={"31px"} position={"relative"}>
            <Checkbox
              variant={getCurrentColor(colorMode, "black", "white")}
              id="agree"
              {...register("agree", {
                required: "This is required",
              })}
            >
              <Text fontSize={"13px"}>
                Agree to our{" "}
                <Link
                  href="#"
                  textDecoration={"underline"}
                  _hover={{ textDecoration: "none" }}
                >
                  Terms of use
                </Link>{" "}
                and{" "}
                <Link
                  href="#"
                  textDecoration={"underline"}
                  _hover={{ textDecoration: "none" }}
                >
                  Privacy Policy
                </Link>
              </Text>
            </Checkbox>
            <Text
              position={"absolute"}
              color={"red.500"}
              fontStyle={"12px"}
              bottom={-4}
              left={0}
              fontSize={"12px"}
            >
              {errors.agree && errors.agree.message}
            </Text>
          </Flex>
          <Button
            bg={"blueC.400"}
            _hover={{ bg: "blueC.200" }}
            color={"whiteC.100"}
            type="submit"
            w={"100%"}
            isLoading={isSubmitting}
          >
            Sign in
          </Button>

          <Box
            w={"100%"}
            h={"1px"}
            bg={getCurrentColor(colorMode, "blackC.900", "grayC.2400")}
            my={"24px"}
          ></Box>
          <Text
            fontSize={"14px"}
            color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
            fontWeight={"500"}
          >
            Already have an account?{" "}
            <Link
              href="/login"
              color={getCurrentColor(colorMode, "blueC.400", "whiteC.100")}
              fontSize={"13px"}
              fontWeight={"500"}
            >
              Login now
            </Link>
          </Text>
        </form>
      </Box>
    </Flex>
  );
};

export default Singup;
