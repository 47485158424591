const getMinMaxForRange = (data) => {
  const extractNumber = (str) => str.match(/\d+/g).map(Number);

  const numericValues = data
    .map((item) => {
      return extractNumber((Math.ceil(item.alt_name) || item.name) + "")[0];
    })
    .sort((a, b) => +a - +b);

  return [numericValues[0], numericValues[numericValues.length - 1]];
};

export default getMinMaxForRange;
