import { Box, Flex, Heading, Text, useColorMode } from "@chakra-ui/react";
import Form from "../Form/Form";
import { getCurrentColor } from "../../constants";
const MainPageForm = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      flexDirection={"column"}
      pt={"52px"}
      pb={"60px"}
      maxW={"660px"}
      mx={"auto"}
      px={{ base: "22px", md: "0px" }}
    >
      <Heading
        textAlign={"center"}
        mb={"10px"}
        fontSize={{ base: "25px", md: "3xl" }}
        fontFamily={"mainFont"}
        fontWeight={"600"}
        color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
      >
        Contact Us
      </Heading>
      <Text
        textAlign={"center"}
        mb={"25px"}
        fontSize={"lg"}
        fontFamily={"mainFont"}
        fontWeight={"400"}
        color={getCurrentColor(colorMode, "grayC.100", "grayC.1600")}
      >
        Have questions? Get in touch with our team.
      </Text>
      <Box maxW={"400px"} w={"100%"} mx={"auto"}>
        <Form />
      </Box>
    </Flex>
  );
};

export default MainPageForm;
