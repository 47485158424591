import {
  Box,
  Flex,
  List,
  ListItem,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";

const ProductSkeletonMobile = () => {
  return (
    <Flex
      border={"1px solid"}
      borderColor={"grayC.500"}
      borderRadius={"5px"}
      pt={"17px"}
      pb={3}
      px={5}
      gap={{ base: 0, md: 42 }}
      position={"relative"}
      maxW={{ base: "320px", "1xs": "378px", lg: "350px" }}
      w={"100%"}
      boxShadow={
        "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
      }
      flexDirection={"column"}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Skeleton height={"30px"} mb={"5px"} w={"50%"} />
        <Box
          cursor={"pointer"}
          border={"1px solid"}
          display={"flex"}
          bottom={"10px"}
          right={"10px"}
          justifyContent={"center"}
          alignItems={"center"}
          borderColor={"grayC.500"}
          borderRadius={"50%"}
          w={"20px"}
          h={"20px"}
        >
          <SkeletonCircle w={"15px"} h={"15px"} />
        </Box>
      </Flex>
      <Flex flexDirection={"column"} w={"100%"}>
        <Flex w={"100%"} justifyContent={"space-between"} gap={14} mb={"12px"}>
          <List
            display={"flex"}
            w={"100%"}
            gap={3}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <ListItem width={"20%"}>
              <Skeleton height={"10px"} w={"100%"} mb={"5px"} />
              <Skeleton height={"20px"} w={"100%"} />
            </ListItem>

            <ListItem width={"20%"}>
              <Skeleton height={"10px"} w={"100%"} mb={"5px"} />
              <Skeleton height={"20px"} w={"100%"} />
            </ListItem>
            <ListItem width={"20%"}>
              <Skeleton height={"10px"} w={"100%"} mb={"5px"} />
              <Skeleton height={"20px"} w={"100%"} />
            </ListItem>
            <ListItem width={"20%"}>
              <Skeleton height={"10px"} w={"100%"} mb={"5px"} />
              <Skeleton height={"20px"} w={"100%"} />
            </ListItem>
          </List>
        </Flex>
        <Flex gap={"8px"} flexWrap={"wrap"} mb={3}>
          <Skeleton w={"20px"} h={"13px"} />
          <Skeleton w={"25px"} h={"13px"} />
          <Skeleton w={"17px"} h={"13px"} />
          <Skeleton w={"30px"} h={"13px"} />
          <Skeleton w={"35px"} h={"13px"} />
          <Skeleton w={"19px"} h={"13px"} />

          <Skeleton w={"20px"} h={"13px"} />
          <Skeleton w={"25px"} h={"13px"} />
          <Skeleton w={"17px"} h={"13px"} />
          <Skeleton w={"30px"} h={"13px"} />
          <Skeleton w={"35px"} h={"13px"} />
          <Skeleton w={"19px"} h={"13px"} />
        </Flex>
      </Flex>
      <Flex justifyContent={"space-between"} gap={2} mt={"auto"}>
        <Flex flexDirection={"column"} w={"50%"}>
          <Skeleton height={"20px"} w={"100%"} mb={"5px"} />

          <Skeleton height={"15px"} w={"100%"} mb={"5px"} />
        </Flex>
        <Skeleton height={"40px"} w={"50%"} mb={"5px"} />
      </Flex>
    </Flex>
  );
};

export default ProductSkeletonMobile;
