import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "jotai";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as RouterProvider } from "react-router-dom";

import App from "./components/App/App";

import "./assets/css/index.css";
import { theme } from "./assets/theme/theme";
import { CookiesProvider } from "react-cookie";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <RouterProvider>
        <Provider>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              <ColorModeScript
                initialColorMode={theme.config.initialColorMode}
              />
              <App />
            </ChakraProvider>
          </QueryClientProvider>
        </Provider>
      </RouterProvider>
    </CookiesProvider>
  </React.StrictMode>,
);
