import { Box, Button, Flex, Text, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const RefreshPopup = () => {
  const { colorMode } = useColorMode();
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  let timer;

  useEffect(() => {
    if (!isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isActive]);

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        setIsActive(false);
      },
      30 * 60 * 1000,
    );
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };

    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);

    resetTimer();

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, []);

  return (
    !isActive && (
      <Box
        position={"fixed"}
        bg={getCurrentColor(
          colorMode,
          "rgba(255,255,255, .7)",
          "rgba(33, 41, 54, .7)",
        )}
        w={"100%"}
        h={"100%"}
        zIndex={200}
        top={0}
        left={0}
      >
        <Flex
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          w={"100%"}
          maxW={"300px"}
          h={"200px"}
          position={"relative"}
          top={"50%"}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
          borderRadius={"10px"}
          border={"1px solid"}
          borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.2400")}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Text
            textAlign={"center"}
            fontSize={{ base: "sm", md: "md" }}
            fontWeight={600}
            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
            mb={"20px"}
          >
            Some results may now be out of stock, please refresh the page.
          </Text>
          <Flex w={"100%"} justifyContent={"space-around"}>
            <Button
              bg={"blueC.100"}
              onClick={() => {
                setIsActive(true);
                navigate(0);
              }}
              color={"whiteC.100"}
              _hover={{ bg: "blueC.200" }}
            >
              REFRESH
            </Button>
            <Button
              onClick={() => {
                setIsActive(true);
                navigate("/");
              }}
              w={"103px"}
            >
              HOME
            </Button>
          </Flex>
        </Flex>
      </Box>
    )
  );
};

export default RefreshPopup;
