import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const BreadcrumbCustom = () => {
  const location = useLocation();

  const getPath = location.pathname
    .split("/")
    .filter((path) => path.length > 0)
    .map((path) => path.replace(/[-]/g, " "));

  return (
    <Breadcrumb
      display={{ base: "block", lg: "none" }}
      mb={4}
      separator={<ChevronRightIcon color="gray.500" />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          fontWeight={"500"}
          fontSize={{ base: "xs", "1xs": "sm" }}
          href="/"
        >
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>

      {getPath.length > 0 &&
        getPath.map((path, index, arr) =>
          index === arr.length - 1 ? (
            <BreadcrumbItem
              key={path}
              color={"grayC.200"}
              textTransform={"capitalize"}
            >
              <Text fontSize={{ base: "xs", "1xs": "sm" }}>
                {decodeURIComponent(path)}
              </Text>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={path}>
              <BreadcrumbLink
                fontWeight={"500"}
                textTransform={"capitalize"}
                href={`/${path}`}
                fontSize={{ base: "xs", "1xs": "sm" }}
              >
                {decodeURIComponent(path)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ),
        )}
    </Breadcrumb>
  );
};

export default BreadcrumbCustom;
