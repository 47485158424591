import { getCurrentColor } from "../../constants";
import SelectSort from "../Select/SelectSort";

import BreadcrumbCustom from "../BreadcrumbCustom/BreadcrumbCustom";

import { Flex, Heading, Skeleton, Text, useColorMode } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import {
  allProductsAtom,
  productsLoadingStatusAtom,
} from "../../atom/products";

const InfoOnProductsPage = () => {
  const { colorMode } = useColorMode();

  const location = useLocation();
  const [productsLoadingStatus] = useAtom(productsLoadingStatusAtom);
  const [allProducts] = useAtom(allProductsAtom);

  return (
    <>
      <BreadcrumbCustom />
      <Flex
        maxW={{ base: "100%", xl: "995px" }}
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        ml={"auto"}
        mb={{ base: "23px", md: "33px" }}
      >
        <Flex flexDirection={"column"} gap={"5px"}>
          <Heading
            fontSize={"22px"}
            color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
            fontWeight={"600"}
            fontFamily={"mainFont"}
          >
            {decodeURIComponent(location.pathname)
              .split("/")
              .slice(2)
              .join("/")}
          </Heading>
          {productsLoadingStatus !== "loading" ? (
            <Text
              fontSize={"md"}
              color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
              fontWeight={"400"}
              fontFamily={"mainFont"}
            >
              {/* {currentPage} page for  */}
              {allProducts} results.
            </Text>
          ) : (
            <Skeleton w={"100px"} h={"20px"} />
          )}
        </Flex>
        <SelectSort />
      </Flex>
    </>
  );
};

export default InfoOnProductsPage;
