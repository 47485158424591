import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Turn as Hamburger } from "hamburger-react";

import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  PhoneIcon,
  TwitterIcon,
  UserIcon,
  YouTubeIcon,
} from "../../assets/icons/icons";
import { useEffect, useState } from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { data } from "../../data";
import useLogout from "../../hooks/useLogout";

import { Link as RouterLink } from "react-router-dom";
import { getCurrentColor, headerLink } from "../../constants";
import RenderDesktopCategories from "../RenderDesktopCategories/RenderDesktopCategories";
import { useAtom } from "jotai";
import { userInfoAtom } from "../../atom/user";
import useCookie from "../../hooks/useCookie";

const Header = () => {
  const { cookies } = useCookie();
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useLogout();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isScrolled, setIsScrolled] = useState(false);
  const [userInfo] = useAtom(userInfoAtom);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMenu]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 72) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderMobileCategories = () => {
    return data.map((item) => {
      const { category, categoryLink, subCategories } = item;
      return (
        <AccordionItem key={category} border={"none"}>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
                fontSize={"lg"}
                flex="1"
                textAlign="left"
              >
                {category}
              </Box>
              <AccordionIcon
                color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
              />
            </AccordionButton>
          </h2>
          <AccordionPanel
            pb={4}
            bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1900")}
          >
            <Flex flexWrap={"wrap"}>
              {subCategories.map((subCategory, index) => {
                const { name } = subCategory;
                return (
                  <Link
                    as={RouterLink}
                    to={headerLink(category, name, cookies.zip)}
                    display={"flex"}
                    alignItems={"center"}
                    fontWeight={500}
                    color={getCurrentColor(
                      colorMode,
                      "blackC.400",
                      "grayC.1600",
                    )}
                    gap={2}
                    py={2}
                    w={"49%"}
                    key={name}
                    onClick={() => setShowMenu(false)}
                  >
                    <ArrowForwardIcon />
                    {name}
                  </Link>
                );
              })}
            </Flex>
            <Link
              as={RouterLink}
              w={{ base: "100%", xs: "70%", md: "100%" }}
              display={"flex"}
              justifyContent={"center"}
              to={categoryLink}
              border={"1px solid"}
              borderColor={"blueC.100"}
              color={getCurrentColor(colorMode, "blueC.100", "whiteC.100")}
              fontSize={"15px"}
              px={"18px"}
              py={"12px"}
              borderRadius={"6px"}
              mt={"24px"}
              fontWeight={500}
              bg={getCurrentColor(colorMode, "whiteC.100", "blueC.400")}
              onClick={() => setShowMenu(false)}
            >
              See More {category}
            </Link>
          </AccordionPanel>
        </AccordionItem>
      );
    });
  };

  const renderDesktopHelloUser = () => {
    return userInfo === null ? (
      <>
        <UserIcon />
        <Link as={RouterLink} to="/signup" color={"whiteC.100"} variant="link">
          Sign up
        </Link>
        <Text color={"whiteC.100"}>|</Text>
        <Link as={RouterLink} to="/login" color={"whiteC.100"} variant="link">
          Login
        </Link>
      </>
    ) : (
      <>
        <Text color={"whiteC.100"}>Hello, {userInfo.given_name}</Text>
        <Button
          variant={"link"}
          color={"whiteC.100"}
          onClick={() => logout(userInfo.id)}
        >
          Log out
        </Button>
      </>
    );
  };

  const renderMobile = () => {
    return userInfo === null ? (
      <>
        <UserIcon />
        <Link
          as={RouterLink}
          to="/signup"
          color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
          fontSize={"lg"}
          variant="link"
          onClick={() => setShowMenu(false)}
        >
          Sign up
        </Link>
        <Text
          color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
          fontSize={"lg"}
        >
          |
        </Text>
        <Link
          as={RouterLink}
          to="/login"
          color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
          fontSize={"lg"}
          variant="link"
          onClick={() => setShowMenu(false)}
        >
          Login
        </Link>
      </>
    ) : (
      <>
        <Button
          variant={"link"}
          color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
          onClick={() => {
            logout(userInfo.id);
            setShowMenu(false);
          }}
        >
          Log out
        </Button>
      </>
    );
  };

  return (
    <>
      {/* desctop header */}
      <Flex
        flexDirection={"column"}
        display={{ base: "none", xl: "flex" }}
        zIndex={"99"}
      >
        <Flex
          gap={5}
          maxW={"1240px"}
          mx={"auto"}
          justifyContent={"space-between"}
          w={"100%"}
          py={4}
          px={"10px"}
        >
          <Flex gap={5}>
            <Link
              as={RouterLink}
              to="mailto:info@bulletscout.com"
              display={"flex"}
              gap={1}
              alignItems={"center"}
              justifyContent={"center"}
              _hover={{ textDecoration: "none" }}
              color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
              fontWeight={"600"}
              fontSize={"sm"}
            >
              <MailIcon /> info@bulletscout.com
            </Link>

            <Flex align={"center"}>
              <Switch
                colorScheme="blue"
                isChecked={colorMode === "dark"}
                onChange={toggleColorMode}
              />
              <Text
                cursor={"pointer"}
                onClick={toggleColorMode}
                ml={2}
                display={"flex"}
                alignItems={"center"}
                fontWeight={500}
                fontSize={"14px"}
                color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
              >
                <ArrowBackIcon
                  mr={1}
                  color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
                />
                Try {colorMode === "dark" ? "light" : "dark"} mode!
              </Text>
            </Flex>
          </Flex>
          <Flex gap={4} alignItems={"flex-end"}>
            <Text
              color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
              fontWeight={"600"}
              fontSize={"sm"}
            >
              Follow Us :
            </Text>
            <Flex gap={4}>
              <Link as={RouterLink} to="#">
                <InstagramIcon />
              </Link>
              <Link as={RouterLink} to="#">
                <YouTubeIcon />
              </Link>
              <Link as={RouterLink} to="#">
                <FacebookIcon />
              </Link>
              <Link as={RouterLink} to="#">
                <TwitterIcon />
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Box
          bg={getCurrentColor(colorMode, "blueC.600", "grayC.1700")}
          position={isScrolled ? "fixed" : "relative"}
          top={isScrolled ? 0 : "auto"}
          left={0}
          w={"100%"}
          transition={"all 0.5s ease"}
        >
          <Flex
            maxW={"1240px"}
            mx={"auto"}
            py={3}
            px={"10px"}
            justifyContent={"space-between"}
          >
            <Link
              as={RouterLink}
              to="/"
              color={"whiteC.100"}
              fontSize={"22px"}
              fontFamily={"secondFont"}
              fontWeight={600}
              textDecor={"none"}
              _hover={{ textDecoration: "none" }}
            >
              {/* Bullet Scout */}
              <Image
                src="https://cdn.bulletscout.com/image/logo-light.png"
                w={"145px"}
                h={"auto"}
                alt="logo"
              />
            </Link>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              maxW={"716px"}
              w={"100%"}
              position={"relative"}
            >
              {data.map((item, index) => (
                <RenderDesktopCategories key={index} data={item} />
              ))}
              <Link
                as={RouterLink}
                to="/about-us"
                color={"whiteC.100"}
                _hover={{ textDecoration: "none" }}
              >
                About Us
              </Link>
            </Flex>

            <Flex alignItems={"center"} gap={2}>
              {renderDesktopHelloUser()}
            </Flex>
          </Flex>
        </Box>
      </Flex>

      {/* mobile header */}
      <Flex
        zIndex={100}
        bg={getCurrentColor(colorMode, "blueC.600", "grayC.1700")}
        px={"20px"}
        py={4}
        display={{ base: "flex", xl: "none" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <Link
          as={RouterLink}
          to="/"
          textDecor={"none"}
          _active={{ textDecor: "none" }}
          fontSize={"28px"}
          color={"whiteC.100"}
          fontWeight={600}
        >
          {/* Bullet Scout */}
          <Image
            src="https://cdn.bulletscout.com/image/logo-light.png"
            alt="logo"
            w={"108px"}
            h={"auto"}
          />
        </Link>
        <Flex
          align={"center"}
          flexDirection={{ base: "column-reverse", "1xs": "row" }}
        >
          <Switch
            colorScheme="blue"
            isChecked={colorMode === "dark"}
            onChange={toggleColorMode}
            mt={{ base: 2, "1xs": 0 }}
          />
          <Text
            cursor={"pointer"}
            onClick={toggleColorMode}
            ml={{ base: 0, xs: 2 }}
            display={"flex"}
            alignItems={"center"}
            fontWeight={500}
            fontSize={"l"}
            color={"grayC.1600"}
            flexDirection={{ base: "column-reverse", xs: "row" }}
          >
            <ArrowBackIcon
              mb={{ base: 1, xs: 0 }}
              mr={{ base: 0, xs: 1 }}
              color={"grayC.1600"}
              transform={{ base: "rotate(-90deg)", "1xs": "rotate(0deg)" }}
            />
            Try {colorMode === "dark" ? "light" : "dark"} mode!
          </Text>
        </Flex>
        <Hamburger color="#fff" toggled={showMenu} toggle={setShowMenu} />

        <Flex
          position={"absolute"}
          overflow={"hidden"}
          w={"100%"}
          transition={"all .3s"}
          top={"90px"}
          left={0}
          overflowY={"auto"}
          zIndex={99}
          bg={getCurrentColor(colorMode, "blueC.600", "grayC.1700")}
          className="scroll-off"
          maxH={showMenu ? "1000px" : "0px"}
          h={showMenu ? "90vh" : "0px"}
          flexDirection={"column"}
          borderTop={"1px solid"}
          borderColor={showMenu ? "grayC.1800" : "transparent"}
        >
          <Accordion w={"100%"} defaultIndex={[0]} allowMultiple>
            {renderMobileCategories()}
          </Accordion>
          <Link
            as={RouterLink}
            to="/about-us"
            fontSize={"lg"}
            px={4}
            py={2}
            mb={11}
            color={getCurrentColor(colorMode, "whiteC.100", "grayC.1600")}
            onClick={() => setShowMenu(false)}
          >
            About Us
          </Link>

          <Flex px={4} alignItems={"center"} gap={2} pb={10}>
            {renderMobile()}
          </Flex>

          <Flex
            gap={5}
            maxW={"1240px"}
            mx={"auto"}
            alignItems={"flex-start"}
            w={"100%"}
            py={6}
            px={"10px"}
            bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
            mt={"auto"}
            flexDirection={"column"}
            pb={10}
          >
            <Flex gap={5} flexDirection={"column"}>
              <Link
                as={RouterLink}
                to="tel:(225) 555-0000"
                display={"flex"}
                gap={1}
                alignItems={"center"}
                _hover={{ textDecoration: "none" }}
                color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
                fontWeight={"600"}
                fontSize={"sm"}
              >
                <PhoneIcon
                  color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
                />
                (225) 555-0000
              </Link>
              <Link
                as={RouterLink}
                to="mailto:info@bulletscout.com"
                display={"flex"}
                gap={1}
                alignItems={"center"}
                justifyContent={"center"}
                _hover={{ textDecoration: "none" }}
                color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
                fontWeight={"600"}
                fontSize={"sm"}
              >
                <MailIcon
                  color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
                />{" "}
                info@bulletscout.com
              </Link>
            </Flex>
            <Flex gap={4} alignItems={"flex-end"}>
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "grayC.1600")}
                fontWeight={"600"}
                fontSize={"sm"}
              >
                Follow Us :
              </Text>
              <Flex gap={4}>
                <Link as={RouterLink} to="#">
                  <InstagramIcon
                    color={getCurrentColor(
                      colorMode,
                      "blackC.100",
                      "grayC.1600",
                    )}
                  />
                </Link>
                <Link as={RouterLink} to="#">
                  <YouTubeIcon
                    color={getCurrentColor(
                      colorMode,
                      "blackC.100",
                      "grayC.1600",
                    )}
                  />
                </Link>
                <Link as={RouterLink} to="#">
                  <FacebookIcon
                    color={getCurrentColor(
                      colorMode,
                      "blackC.100",
                      "grayC.1600",
                    )}
                  />
                </Link>
                <Link as={RouterLink} to="#">
                  <TwitterIcon
                    color={getCurrentColor(
                      colorMode,
                      "blackC.100",
                      "grayC.1600",
                    )}
                  />
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
