import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Link,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Timer from "../components/Timer/Timer";
import useConfirmRegistration from "../hooks/useConfirmRegistration";
import useResendConfirmationCode from "../hooks/useResendConfirmationCode";
import { Helmet } from "react-helmet";
import { getCurrentColor } from "../constants";

const ConfirmEmailPage = () => {
  const { colorMode } = useColorMode();
  const {
    register,
    formState: { isSubmitting },
    getValues,
  } = useForm();
  const location = useLocation();
  const getUserId = location.pathname.split("/")[2];
  const { confirmEmail, codeError, confirmRegistration } =
    useConfirmRegistration();
  const { manyResends, resendingConfirmationCode } =
    useResendConfirmationCode();
  const [resendConfirmCode, setResendConfirmCode] = useState(false);

  useEffect(() => {
    if (manyResends) {
      setResendConfirmCode(false);
    }
  }, [manyResends]);
  const enterConfirmCode = () => {
    return (
      <Flex
        w={"350px"}
        mx={"auto"}
        flexDirection={"column"}
        pt={"30px"}
        pb={{ base: "50px", md: "100px" }}
      >
        <Helmet>
          <title>Confirm Email to Bullet Scout</title>
          <meta
            name="description"
            content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
          />
        </Helmet>
        <Heading
          as={"h2"}
          fontSize={{ base: "30px", md: "35px" }}
          fontWeight={600}
          fontFamily={"mainFont"}
          mb={"20px"}
        >
          Confirm your email
        </Heading>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            confirmRegistration(getUserId, getValues("confirm_email"));
          }}
        >
          <FormControl position={"relative"}>
            <Input
              mb={"20px"}
              placeholder="Enter email code"
              bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
              border={"0.5px solid"}
              borderColor={codeError ? "red.500" : "grayC.1400"}
              focusBorderColor={codeError ? "red.500" : "grayC.1300"}
              id="confirm_email"
              name="confirm_email"
              {...register("confirm_email", {
                required: "This is required",
                minLength: { value: 4, message: "Code can be min 4 symbols" },
              })}
            />
            {codeError && (
              <Text
                color={"red.500"}
                fontSize={"12px"}
                bottom={0}
                left={0}
                position={"absolute"}
              >
                Pleace, write right code
              </Text>
            )}

            {manyResends && (
              <Text
                color={"red.500"}
                fontSize={"12px"}
                bottom={0}
                left={0}
                position={"absolute"}
              >
                Attempt limit exceeded, please try after some time.
              </Text>
            )}
          </FormControl>
          <Flex justifyContent={"space-between"} w={"100%"}>
            <Button
              mt={"10px"}
              bg={"blueC.100"}
              color={"whiteC.100"}
              _hover={{ bg: "blueC.200" }}
              isLoading={isSubmitting}
              type="submit"
            >
              Confirm email
            </Button>
            <Button
              mt={"10px"}
              bg={"blueC.100"}
              color={"whiteC.100"}
              _hover={{ bg: "blueC.200" }}
              isLoading={isSubmitting}
              isDisabled={resendConfirmCode}
              w={"141px"}
              _disabled={{
                opacity: 0.6,
                _hover: { bg: "blueC.100" },
                cursor: "auto",
              }}
              onClick={() => {
                setResendConfirmCode(true);
                resendingConfirmationCode(getUserId);
              }}
            >
              {resendConfirmCode ? (
                <Timer setComplated={setResendConfirmCode} time={120000} />
              ) : (
                "Reasend code"
              )}
            </Button>
          </Flex>
        </form>
      </Flex>
    );
  };

  const confirmEmailMarkup = () => {
    return (
      <Flex
        w={"350px"}
        mx={"auto"}
        flexDirection={"column"}
        pt={"30px"}
        pb={{ base: "50px", md: "100px" }}
      >
        <Heading
          as={"h2"}
          fontSize={{ base: "30px", md: "35px" }}
          fontWeight={600}
          fontFamily={"mainFont"}
          mb={"20px"}
          textAlign={"center"}
        >
          Your email has been verified
        </Heading>
        <Link
          href="/login"
          bg={"blueC.100"}
          color={"whiteC.100"}
          _hover={{ bg: "blueC.200" }}
          borderRadius={"8px"}
          textAlign={"center"}
          py={"10px"}
        >
          Sing in
        </Link>
      </Flex>
    );
  };

  return (
    <Flex>{confirmEmail ? confirmEmailMarkup() : enterConfirmCode()}</Flex>
  );
};

export default ConfirmEmailPage;
