import { Button, Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";

const ErrorFormSend = ({ setError }) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Text
        mb={"10px"}
        color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
      >
        Form senting is failed
      </Text>

      <Text color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}>
        Please try again
      </Text>
      <Image
        src="https://cdn.bulletscout.com/image/try-again.webp"
        alt="Try again"
        w={"350px"}
      />
      <Button mt={"20px"} onClick={() => setError(false)}>
        Try another one
      </Button>
    </Flex>
  );
};

export default ErrorFormSend;
