import { Text } from "@chakra-ui/react";
import Countdown from "react-countdown";

const Timer = ({ time, setComplated }) => {
  const rendererTimer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return setTimeout(() => setComplated(false), 10);
    } else {
      return (
        <Text color={"whiteC.100"} fontSize={"18px"} fontWeight={"600"}>
          {minutes}:{seconds}
        </Text>
      );
    }
  };
  return (
    <Countdown
      date={Date.now() + time}
      zeroPadTime={10}
      renderer={rendererTimer}
    />
  );
};

export default Timer;
