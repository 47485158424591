import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";

import { Rating as ReactRating, Star } from "@smastrom/react-rating";
import styles from "@smastrom/react-rating/style.css";

const RatingCustom = ({ rating, reviews }) => {
  const { colorMode } = useColorMode();
  const myStyles = {
    itemShapes: Star,
    activeFillColor: "#ffb700",
    inactiveFillColor: getCurrentColor(colorMode, "#f7f0de", "#45474b"),
  };

  return (
    <Flex alignItems={"center"} gap={"5px"}>
      <Box w={"90px"}>
        <ReactRating
          style={styles}
          value={rating}
          readOnly={true}
          itemStyles={myStyles}
        />
      </Box>

      {+reviews > 0 && (
        <Text
          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          fontSize={"11px"}
          fontFamily={"mainFont"}
        >
          ({reviews})
        </Text>
      )}
    </Flex>
  );
};

export default RatingCustom;
