import {
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import getMinMaxForRange from "../../utils/getMinMaxForRange";
import { useLocation, useNavigate } from "react-router-dom";
import useSelectedFilter from "../../hooks/useSelectedFilter";

const RangeSliderCustom = ({ data, updateRange, values, title }) => {
  const { selectFilter } = useSelectedFilter();
  const location = useLocation();
  const navigate = useNavigate();

  const curentQueryTitle =
    title === "Bullet Weight" || title === "Shot Weight" ? "bw" : "rpb";

  const queryStringCurrent = decodeURIComponent(location.search)
    .slice(1)
    .split("&")
    .filter((item) => item.startsWith(`${curentQueryTitle}=`));

  const rangeValuesFromLocation = queryStringCurrent

    .filter((item) => item.includes("-"))[0]
    ?.split("=")[1]
    ?.split("-")
    ?.map((value, index) => {
      return value.includes(",")
        ? index === 1
          ? value?.split(",")[0]
          : value?.split(",")[1]
        : value;
    });

  const [min] = useState(getMinMaxForRange(data)[0]);
  const [max] = useState(getMinMaxForRange(data)[1]);

  const [rangeValues, setRangeValues] = useState([
    rangeValuesFromLocation
      ? rangeValuesFromLocation[0]
      : getMinMaxForRange(data)[0],
    rangeValuesFromLocation
      ? max === min || max === min + 1
        ? max + 1
        : rangeValuesFromLocation[1]
      : max === min || max === min + 1
        ? max + 1
        : getMinMaxForRange(data)[1],
  ]);

  const updateProduct = () => {
    const queryString = location.search
      .slice(1)
      .split("&")
      .filter((item) => !item.startsWith(`${curentQueryTitle}=`))
      .join("&");

    if (getMinMaxForRange(data)[1] < max || getMinMaxForRange(data)[0] > min) {
      selectFilter({
        title: curentQueryTitle,
        value: `${rangeValues[0]}-${rangeValues[1]}`,
      });
    } else {
      navigate(
        `${location.pathname}?${queryString}${queryStringCurrent
          .filter((item) => !item.includes("-"))
          .sort((a, b) => a.title - b.title)
          .join("&")}`,
      );
    }
  };

  useEffect(() => {
    updateRange([min, max]);

    // eslint-disable-next-line
  }, [min, max]);

  const handleChange = useCallback(
    (e) => {
      setRangeValues(e);
      updateRange(e);
    },
    // eslint-disable-next-line
    [values],
  );

  return (
    <Flex w={"100%"} flexDirection={"column"} mb={"21px"}>
      <Flex justifyContent={"space-between"}>
        <Text>{rangeValues[0]}</Text>
        <Text>{rangeValues[1]}</Text>
      </Flex>
      <RangeSlider
        aria-label={'["min", "max"]'}
        onChange={(e) => handleChange(e)}
        onChangeEnd={() => updateProduct()}
        min={min}
        max={max === min || max === min + 1 ? max + 1 : max}
        value={[rangeValues[0], rangeValues[1]]}
        step={Math.round(max / 100) < 1 ? max / 100 : Math.round(max / 100)}
        minStepsBetweenThumbs={1}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack bg={"blueC.100"} />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} bg={"blueC.100"} />
        <RangeSliderThumb index={1} bg={"blueC.100"} />
      </RangeSlider>
    </Flex>
  );
};

export default RangeSliderCustom;
