import {
  Box,
  Flex,
  List,
  ListItem,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";

const ProductSkeletonDesctop = () => {
  return (
    <Flex
      border={"1px solid"}
      mb={"25px"}
      borderColor={"grayC.500"}
      borderRadius={"5px"}
      pt={{ base: "17px", md: 8 }}
      pb={{ base: 3, md: "34px" }}
      px={5}
      gap={{ base: 0, md: 10 }}
      position={"relative"}
      w={"100%"}
      boxShadow={
        "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
      }
    >
      <Box maxW={{ base: "320px", "1xs": "378px", lg: "135px" }} w={"100%"}>
        <Skeleton height={"30px"} mb={"5px"} />
        <Skeleton height={"10px"} mb={"5px"} />
        <Skeleton height={"10px"} mb={"5px"} />
        <Skeleton height={"20px"} mb={"5px"} />
      </Box>
      <Flex flexDirection={"column"} w={"100%"}>
        <Flex w={"100%"} justifyContent={"space-between"} gap={14} mb={"12px"}>
          <List
            display={"flex"}
            w={"100%"}
            gap={3}
            justifyContent={"space-between"}
          >
            <ListItem w={"25%"}>
              <Skeleton height={"7px"} mb={"5px"} w={""} />
              <Skeleton height={"20px"} mb={"25px"} w={""} />
            </ListItem>

            <ListItem>
              <Skeleton height={"7px"} mb={"5px"} width={"50px"} />
              <Skeleton height={"20px"} mb={"25px"} w={""} />
            </ListItem>

            <ListItem>
              <Skeleton height={"7px"} mb={"5px"} width={"70px"} />
              <Skeleton height={"20px"} mb={"25px"} w={""} />
            </ListItem>

            <ListItem>
              <Skeleton height={"7px"} mb={"5px"} width={"90px"} />
              <Skeleton height={"20px"} mb={"25px"} w={""} />
            </ListItem>
          </List>
          <Flex h={"100%"} alignItems={"center"}>
            <Skeleton height={"40px"} w={"150px"} />
          </Flex>
        </Flex>
        <Flex gap={"8px"} flexWrap={"wrap"} maxW={"600px"}>
          <Skeleton w={"20px"} h={"13px"} />
          <Skeleton w={"25px"} h={"13px"} />
          <Skeleton w={"17px"} h={"13px"} />
          <Skeleton w={"30px"} h={"13px"} />
          <Skeleton w={"35px"} h={"13px"} />
          <Skeleton w={"19px"} h={"13px"} />

          <Skeleton w={"20px"} h={"13px"} />
          <Skeleton w={"25px"} h={"13px"} />
          <Skeleton w={"17px"} h={"13px"} />
          <Skeleton w={"30px"} h={"13px"} />
          <Skeleton w={"35px"} h={"13px"} />
          <Skeleton w={"19px"} h={"13px"} />
        </Flex>
      </Flex>

      <Flex

        border={"1px solid"}
        bottom={"10px"}
        right={"10px"}
        justifyContent={"center"}
        alignItems={"center"}
        borderColor={"grayC.500"}
        position={"absolute"}
        borderRadius={"50%"}
        w={"20px"}
        h={"20px"}
      >
        <SkeletonCircle w={"15px"} h={"15px"} />
      </Flex>
    </Flex>
  );
};

export default ProductSkeletonDesctop;
