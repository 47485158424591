import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { useNavigate } from "react-router-dom";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { useForm } from "react-hook-form";
import { emailReg, getCurrentColor, passwordValidate } from "../constants";

import { transformUserData } from "../utils/transformUserData";
import { Helmet } from "react-helmet";
import { useAtom } from "jotai";
import { rememberUser, userInfoAtom } from "../atom/user";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID,
  ClientId: process.env.REACT_APP_APPCLIENT_ID,
});

const Login = () => {
  const { colorMode } = useColorMode();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useAtom(rememberUser);
  const [, setUserInfo] = useAtom(userInfoAtom);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (values) => {
    const cognitoUser = new CognitoUser({
      Username: values.email.trim(),
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: values.email.trim(),
      Password: values.password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            setError(err.message);
            return;
          }

          setUserInfo(transformUserData(result));

          navigate("/");
          navigate(0);
        });
      },
      onFailure: (err) => {
        console.log("login failed", err);
      },
    });
  };

  return (
    <Flex
      bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
      pt={{ base: "28px", md: "37px" }}
      pb={{ base: "129px", md: "200px" }}
      px={{ base: "20px", md: "10px" }}
    >
      <Helmet>
        <title>Login to Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <Flex
        maxW={"362px"}
        w={"100%"}
        mx={"auto"}
        flexDirection={"column"}
        pt={{ base: "28px", md: "70px" }}
      >
        <Heading
          as={"h2"}
          fontFamily={"mainFont"}
          fontSize={"2xl"}
          mb={{ base: "32px", md: "26px" }}
        >
          Sign in
        </Heading>

        {error && (
          <Text color="red.500" mb={"18px"}>
            {error}
          </Text>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.email}>
            <Input
              placeholder="Email address"
              border={"0.5px solid"}
              bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
              borderColor={getCurrentColor(
                colorMode,
                "grayC.1400",
                "grayC.2200",
              )}
              _placeholder={{
                color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
              }}
              focusBorderColor={"grayC.1300"}
              mb={"18px"}
              id="email"
              name="email"
              {...register("email", {
                required: "This is required",
                pattern: {
                  value: emailReg,
                  message: "Invalid email address",
                },
              })}
            />

            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password}>
            <InputGroup size="md" mb={"29px"}>
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.1400",
                  "grayC.2200",
                )}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "grayC.2100"),
                }}
                focusBorderColor={"grayC.1300"}
                id="password"
                name="password"
                {...register("password", {
                  required: "This is required",
                  validate: passwordValidate,
                  minLength: {
                    value: 8,
                    message: "Minimum length should be 8",
                  },
                })}
              />
              <InputRightElement width="4.5rem">
                <Button
                  bg={"transparent"}
                  border={"none"}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                  _hover={{ bg: "transparent" }}
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>

          <Flex mb={"31px"}>
            <FormControl display="flex" alignItems="center">
              <Switch
                id="remember"
                mr={2}
                value={rememberMe}
                colorScheme="blueC"
                onChange={() => setRememberMe(!rememberMe)}
              />
              <FormLabel
                htmlFor="remember"
                fontWeight={"500"}
                fontSize={"13px"}
                mb="0"
              >
                Remember me
              </FormLabel>
            </FormControl>

            <Link
              fontSize={"13px"}
              w={"100%"}
              maxW={"128px"}
              textAlign={"end"}
              href="/forgot-password"
              color={getCurrentColor(colorMode, "blueC.400", "whiteC.100")}
              fontWeight={"500"}
            >
              Forgot password?
            </Link>
          </Flex>
          <Button
            bg={"blueC.400"}
            _hover={{ bg: "blueC.200" }}
            color={"whiteC.100"}
            w={"100%"}
            type="submit"
            isLoading={isSubmitting}
          >
            Sign in
          </Button>
        </form>

        <Box
          w={"100%"}
          h={"1px"}
          bg={getCurrentColor(colorMode, "blackC.900", "grayC.2400")}
          my={"24px"}
        ></Box>

        <Text
          fontSize={"14px"}
          color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
          fontWeight={"500"}
        >
          Don't have an account?{" "}
          <Link
            href="/signup"
            color={getCurrentColor(colorMode, "blueC.400", "whiteC.100")}
            fontSize={"13px"}
            fontWeight={"500"}
          >
            Sign up now
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
};

export default Login;
