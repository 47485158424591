import { useCookies } from "react-cookie";

const useCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const setMyCookie = (key, value, path) => {
    setCookie(key, value, { path: path });
  };

  const removeMyCookie = (key, path) => {
    removeCookie(key, { path: path });
  };

  return {
    cookies,
    setMyCookie,
    removeMyCookie,
  };
};

export default useCookie;
