import { Flex, Grid, Heading, Skeleton } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import CaliberItem from "../components/CaliberItem/CaliberItem";
import BreadcrumbCustom from "../components/BreadcrumbCustom/BreadcrumbCustom";
import { memo, useEffect } from "react";
import { getTitle } from "../utils/getCategoryName";
import CaliberSkeleton from "../skeletons/CaliberSkeleton/CaliberSkeleton";

import { Helmet } from "react-helmet";
import {
  allCategoryInfoAtom,
  calibersLoadingStatusAtom,
  categoryAtom,
  fetchCalibers,
} from "../atom/calibers";
import { useAtom } from "jotai";

const CategoriesPage = () => {
  const location = useLocation();

  const [allCategoryInfo] = useAtom(allCategoryInfoAtom);
  const [calibersLoading, setCalibersLoading] = useAtom(
    calibersLoadingStatusAtom,
  );
  const [category, setCategory] = useAtom(categoryAtom);

  const [, setCalibers] = useAtom(fetchCalibers);

  useEffect(() => {
    if (allCategoryInfo.length === 0) {
      setCalibers(location.pathname);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    setCalibersLoading("loading");
    setCategory(
      allCategoryInfo.filter((category) =>
        category.weapon_category
          .toLowerCase()
          .includes(
            getTitle(location.pathname).replace(" Ammo", "").toLowerCase(),
          ),
      )[0],
    );
    setCalibersLoading("idle");

    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <Flex
      pt={{ base: "22px", lg: "43px" }}
      maxW={"1240px"}
      w={"100%"}
      mx={"auto"}
      px={{ base: "22px", lg: "10px" }}
      flexDirection={"column"}
    >
      <Helmet>
        <title>{getTitle(location.pathname)}</title>
      </Helmet>
      <BreadcrumbCustom />
      {category?.weapon_category ? (
        <Heading
          w={"100%"}
          textAlign={"center"}
          fontSize={"25px"}
          fontFamily={"mainFont"}
          fontWeight={"600"}
          mb={{ base: 6, md: 8 }}
        >
          {`${category?.weapon_category} Ammo`}
        </Heading>
      ) : (
        <Skeleton w={"250px"} h={"40px"} mx={"auto"} mb={{ base: 6, md: 8 }} />
      )}
      <Grid
        display={"grid"}
        gap={{ base: "9px", md: "21px" }}
        flexWrap={"wrap"}
        w={"100%"}
        mb={{ base: "77px", md: "94px" }}
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
          ".5xl": "repeat(6, 1fr)",
        }}
        justifyItems={"center"}
      >
        {category?.calibers &&
        calibersLoading === "idle" &&
        setTimeout(() => {}, 1000)
          ? category.calibers.map((caliber) => (
              <CaliberItem
                key={caliber.caliber}
                path={location.pathname}
                category={caliber}
                categoryName={category.weapon_category}
              />
            ))
          : [...Array(30)].map((item, index) => {
              return <CaliberSkeleton key={index} />;
            })}
      </Grid>
    </Flex>
  );
};

export default memo(CategoriesPage);
