import {
  Box,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { getCurrentColor, headerLink } from "../../constants";
import { TriangleIcon } from "../../assets/icons/icons";
import { Link as RouterLink } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import useCookie from "../../hooks/useCookie";

const RenderDesktopCategories = ({ data }) => {
  const { cookies } = useCookie();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { category, categoryLink, subCategories, index } = data;
 
  return (
    <Menu
      key={category}
      position={"relative"}
      closeOnSelect={true}
      isOpen={isOpen}
      id={index}
    >
      <MenuButton
        color={"whiteC.100"}
        onMouseOver={onOpen}
        fontSize={"md"}
        fontWeight={"400"}
        onMouseLeave={onClose}
      >
        {category}
      </MenuButton>
      <MenuList
        onMouseOver={onOpen}
        onMouseLeave={onClose}
        p={"30px"}
        bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
        transform={"translateX(-35%) !important"}
        left={"50% !important"}
        position={"absolute"}
        boxSizing="content-box"
        top={"10px"}
        zIndex={200}
        key={`list${category}`}
        onClick={(e) => e.preventDefault()}
      >
        <Box
          height={"50px"}
          width={"100%"}
          position={"absolute"}
          top={"-25px"}
          left={0}
          bg={"transparent"}
        ></Box>
        <Box
          position={"absolute"}
          top={"-12px"}
          left={"50%"}
          transform={"translateX(-50%)"}
        >
          <TriangleIcon
            color={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
            position={"relative"}
            top={"-5px"}
            strokeColor={getCurrentColor(colorMode, "whiteC.100", "grayC.2500")}
            zIndex={1}
          />
          <Box
            zIndex={2}
            position={"absolute"}
            left={"50%"}
            transform={"translateX(-50%)"}
            top={"11px"}
            w={"14.5px"}
            height={"3px"}
            bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          ></Box>
        </Box>
        {subCategories.map((subCategory) => {
          const { name } = subCategory;
          return (
            <MenuItem
              key={name}
              bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
              px={0}
              pl={2}
              rounded={"md"}
            >
              <Link
                w={"100%"}
                as={RouterLink}
                _hover={{ textDecor: "none" }}
                to={headerLink(category, name, cookies.zip)}
                display={"flex"}
                alignItems={"center"}
                fontWeight={500}
                color={getCurrentColor(colorMode, "blackC.400", "whiteC.100")}
                gap={2}
                py={2}
                onClick={onClose}
              >
                <ArrowForwardIcon />
                {name}
              </Link>
            </MenuItem>
          );
        })}
        <MenuItem p={0} mt={"24px"} _hover={{ bg: "transparent" }}>
          <Link
            as={RouterLink}
            w={"100%"}
            display={"flex"}
            justifyContent={"center"}
            to={categoryLink}
            border={"1px solid"}
            borderColor={"blueC.100"}
            color={getCurrentColor(colorMode, "blueC.100", "whiteC.100")}
            bg={getCurrentColor(colorMode, "transparent", "blueC.400")}
            fontSize={"15px"}
            px={"10px"}
            py={"12px"}
            borderRadius={"6px"}
            onClick={onClose}
          >
            See More {category}
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default RenderDesktopCategories;
