import { GridItem, Skeleton, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";

const CaliberSkeleton = () => {
  const { colorMode } = useColorMode();
  return (
    <GridItem
      w={{ base: "150px", "1xs": "185px", md: "190px" }}
      h={{ base: "150px", "1xs": "185px", md: "190px" }}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      border={"1px solid "}
      borderColor={"grayC.500"}
      bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
      pt={{ base: "14px", md: "15px" }}
      borderRadius={"5px"}
    >
      <Skeleton
        w={{ base: "70px", "1xs": "116px", md: "120px" }}
        h={{ base: "60px", "1xs": "86px", md: "90px" }}
        mb={"8px"}
      />

      <Skeleton w={"80%"} h={"10px"} mb={"auto"} />

      <Skeleton w={"100%"} height={"40px"} borderBottomRadius={"5px"} />
    </GridItem>
  );
};

export default CaliberSkeleton;
