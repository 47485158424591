import { atom } from "jotai";
import useHttp from "../hooks/useHttp";
import { getTitle } from "../utils/getCategoryName";

export const calibersLoadingStatusAtom = atom("idle");
export const categoryAtom = atom(null);
export const allCategoryInfoAtom = atom([]);

export const fetchCalibers = atom(null, async (get, set, title) => {
  const { getCalibers } = useHttp();

  set(calibersLoadingStatusAtom, "loading");
  getCalibers()
    .then((response) => {
      set(allCategoryInfoAtom, response);
      set(
        categoryAtom,
        response.filter((category) =>
          category.weapon_category
            .toLowerCase()
            .includes(getTitle(title).replace(" Ammo", "").toLowerCase()),
        )[0],
      );
    })
    .finally(() => set(calibersLoadingStatusAtom, "idle"));
});
