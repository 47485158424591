import { CognitoUserPool } from "amazon-cognito-identity-js";

const useCognitoUserPool = () => {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USERPOOL_ID,
    ClientId: process.env.REACT_APP_APPCLIENT_ID,
  });

  return { userPool };
};

export default useCognitoUserPool;