import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { useState } from "react";
import { getCurrentColor } from "../constants";
import { Helmet } from "react-helmet";

const ComingSoonPage = () => {
  const { colorMode } = useColorMode();
  const [buyerEmail, setBuyerEmail] = useState();
  const [vendorEmail, setVendorEmail] = useState();

  return (
    <Flex px={{ base: "20px", md: "10px" }}>
      <Helmet>
        <title>Coming Soon to Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <Flex
        maxW={"860px"}
        mx={"auto"}
        flexDirection={"column"}
        alignItems={{ base: "flex-start", md: "center" }}
        pt={{ base: "28px", md: "51px" }}
        pb={{ base: "65px", md: "151px" }}
      >
        <Image
          src={getCurrentColor(
            colorMode,
            "https://cdn.bulletscout.com/image/logo-login.png",
            "https://cdn.bulletscout.com/image/logo-light.png",
          )}
          maxW={"145px"}
          alt="Bullet scout logo"
          mb={{ base: "27px", md: "84px" }}
        />
        <Heading
          as={"h1"}
          fontFamily={"mainFont"}
          fontWeight={600}
          fontSize={{ base: "40", md: "60px" }}
          mb={{ base: "15px", md: "12px" }}
          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
        >
          Welcome to Bullet Scout
        </Heading>
        <Text
          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          fontSize={{ base: "18px", md: "22px" }}
          fontWeight={400}
          fontFamily={"mainFont"}
          mb={{ base: "21px", md: "25px" }}
        >
          Revolutionizing Your Ammunition Shopping Experience
        </Text>
        <Box w={"100px"} h={"1px"} bg={"blackC.1000"} mb={"30"}></Box>
        <Text
          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
          fontSize={"16px"}
          mb={{ base: "24px", md: "39px" }}
        >
          We’re gearing up to transform the way you shop for ammo. Our
          innovative platform will provide you with:
        </Text>

        <Flex
          gap={{ base: "23px", md: "60px" }}
          mb={{ base: "53px", md: "65px" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Flex gap={"20px"}>
            <Image
              src={getCurrentColor(
                colorMode,
                "https://cdn.bulletscout.com/image/coming-soon/box-light.png",
                "https://cdn.bulletscout.com/image/coming-soon/box.png",
              )}
              alt="box"
              minW={{ base: "54px", md: "74px" }}
              h={{ base: "54px", md: "74px" }}
            />
            <Box>
              <Heading
                as={"h3"}
                fontSize={"18px"}
                fontWeight={500}
                fontFamily={"mainFont"}
                mb={"14px"}
              >
                Accurate Shipping Estimations
              </Heading>
              <Text
                fontSize={"16px"}
                color={getCurrentColor(colorMode, "blackC.300", "grayC.2600")}
              >
                Say goodbye to unexpected costs. Our advanced system calculates
                precise shipping fees, enabling you to make informed purchasing
                decisions.
              </Text>
            </Box>
          </Flex>

          <Flex gap={"20px"}>
            <Image
              src={getCurrentColor(
                colorMode,
                "https://cdn.bulletscout.com/image/coming-soon/coin-light.png",
                "https://cdn.bulletscout.com/image/coming-soon/coin.png",
              )}
              alt="coin"
              minW={{ base: "54px", md: "74px" }}
              h={{ base: "54px", md: "74px" }}
            />
            <Box>
              <Heading
                as={"h3"}
                fontSize={"18px"}
                fontWeight={500}
                fontFamily={"mainFont"}
                mb={"14px"}
              >
                Total CPR (Cents per Round)
              </Heading>
              <Text
                fontSize={"16px"}
                color={getCurrentColor(colorMode, "blackC.300", "grayC.2600")}
              >
                With Bullet Scout, you can compare prices effectively, factoring
                in shipping costs to determine the true value of your purchase.
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          gap={{ base: "23px", md: "60px" }}
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "flex-start", md: "flex-end" }}
        >
          <Flex gap={"20px"} flexDirection={"column"}>
            <Heading
              fontSize={"18px"}
              fontFamily={"mainFont"}
              fontWeight={500}
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              mb={"9px"}
            >
              For Buyers,
            </Heading>
            <Text
              fontSize={"16px"}
              fontFamily={"mainFont"}
              fontWeight={400}
              color={getCurrentColor(colorMode, "blackC.300", "grayC.2600")}
            >
              Be the first to know when Bullet Scout goes live. Enter your email
              and get exclusive early access.
            </Text>

            <Flex gap={"9.5px"}>
              <Input
                placeholder="Email address"
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.900",
                  "transparent",
                )}
                color={getCurrentColor(colorMode, "grayC.2800", "gray.2900")}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "gray.2900"),
                }}
                value={buyerEmail}
                onChange={(e) => setBuyerEmail(e.target.value)}
                borderRadius={"6px"}
                name="emailBuer"
              />
              <Button
                bg={"blueC.400"}
                color={"whiteC.100"}
                fontSize={"15px"}
                fontWeight={500}
                minW={{ base: "155px", md: "165px" }}
              >
                Get Beta Access
              </Button>
            </Flex>
          </Flex>

          <Box
            w={{ base: "100px", md: "1px" }}
            h={{ base: "1px", md: "140px" }}
            bg={"blackC.1000"}
          ></Box>

          <Flex gap={"20px"} flexDirection={"column"}>
            <Heading
              fontSize={"18px"}
              fontFamily={"mainFont"}
              fontWeight={500}
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              mb={"9px"}
            >
              For Vendors,
            </Heading>
            <Text
              fontSize={"16px"}
              fontFamily={"mainFont"}
              fontWeight={400}
              color={getCurrentColor(colorMode, "blackC.300", "grayC.2600")}
            >
              Join the revolution. Sign up today to receive more information
              about our affiliate program.
            </Text>

            <Flex gap={"9.5px"}>
              <Input
                placeholder="Email address"
                bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.900",
                  "transparent",
                )}
                color={getCurrentColor(colorMode, "grayC.2800", "gray.2900")}
                _placeholder={{
                  color: getCurrentColor(colorMode, "grayC.2800", "gray.2900"),
                }}
                value={vendorEmail}
                onChange={(e) => setVendorEmail(e.target.value)}
                borderRadius={"6px"}
                name="emailVendor"
              />
              <Button
                bg={"blueC.400"}
                color={"whiteC.100"}
                fontSize={"15px"}
                fontWeight={500}
                w={"100%"}
                maxW={{ base: "155px", md: "165px" }}
              >
                Get More Info
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ComingSoonPage;
