import { List, ListItem, Skeleton } from "@chakra-ui/react";

const FilterSkeletonMobile = () => {
  return (
    <List>
      <ListItem>
        <Skeleton height={"50px"} w={"100%"} mb={"5px"} />
        <Skeleton height={"400px"} w={"100%"} />
      </ListItem>
      <ListItem>
        <Skeleton height={"50px"} w={"100%"} mb={"5px"} />
        <Skeleton height={"400px"} w={"100%"} />
      </ListItem>
      <ListItem>
        <Skeleton height={"50px"} w={"100%"} mb={"5px"} />
        <Skeleton height={"400px"} w={"100%"} />
      </ListItem>
      <ListItem>
        <Skeleton height={"50px"} w={"100%"} mb={"5px"} />
        <Skeleton height={"400px"} w={"100%"} />
      </ListItem>
    </List>
  );
};

export default FilterSkeletonMobile;
