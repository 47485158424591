import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { getCurrentColor } from "../constants";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      pt={{ base: "34px", md: "66px" }}
      maxW={"1240px"}
      w={"100%"}
      mx={"auto"}
      pb={{ base: "55px", md: "100px" }}
      px={{ base: "22px", lg: "10px" }}
    >
      <Helmet>
        <title>About Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <Heading
        fontFamily={"mainFont"}
        color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
        mb={{ base: "14px", md: "30px" }}
        maxW={"600px"}
        w={"100%"}
        fontSize={{ base: "30px", md: "40px" }}
      >
        Our vision is to make finding ammo quick and fulfilling
      </Heading>
      <Flex
        gap={{ base: "30px", lg: "40px" }}
        flexDirection={{ base: "column", lg: "row" }}
      >
        <Flex w={{ base: "100%", lg: "50%" }} flexDirection={"column"}>
          <Text mb={6} fontFamily={"mainFont"}>
            Welcome to <Text as={"b"}>Bullet Scout</Text>, your premier
            destination for finding the right ammunition for your firearm needs.
            With our comprehensive search engine, you can explore a wide array
            of ammunition options, ensuring you always hit your mark. Quickly
            narrow down your search by browsing ammunition options categorized
            by caliber, grain, brand and more, ensuring a seamless shopping
            experience.
          </Text>
          <Text fontFamily={"mainFont"}>
            Founded with the vision of providing shooters with a convenient and
            reliable platform to discover and purchase ammunition online, Bullet
            Scout is dedicated to delivering an exceptional shopping experience
            for enthusiasts of all levels.
          </Text>
        </Flex>
        <Flex w={{ base: "100%", lg: "50%" }} flexDirection={"column"}>
          <Heading
            as={"h3"}
            fontSize={"xl"}
            fontWeight={"600"}
            fontFamily={"mainFont"}
            color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
            mb={4}
          >
            Why choose us?
          </Heading>
          <List
            listStyleType={""}
            pl={4}
            display={"flex"}
            flexDirection={"column"}
            gap={{ base: "13px", md: "16px" }}
          >
            <ListItem
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              fontSize={"md"}
              fontFamily={"mainFont"}
            >
              <Text as={"b"} mr={2}>
                Extensive Selection:
              </Text>{" "}
              Access a diverse range of ammunition types, calibers, and brands.
            </ListItem>
            <ListItem
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              fontSize={"md"}
              fontFamily={"mainFont"}
            >
              <Text as={"b"} mr={2}>
                User-Friendly Interface:
              </Text>
              Our intuitive search engine makes finding the perfect ammunition
              quick and effortless.
            </ListItem>
            <ListItem
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              fontSize={"md"}
              fontFamily={"mainFont"}
            >
              <Text as={"b"} mr={2}>
                Expert Guidance:
              </Text>
              Benefit from recommendations and insights to enhance your shooting
              experience.
            </ListItem>
          </List>
        </Flex>
      </Flex>
    </Box>
  );
};
export default AboutUsPage;
