import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import Rating from "../Rating/Rating";
import ToolTipTotal from "../ToolTip/ToolTipTotal";
import { cautionStatus } from "../../utils/cautionStatus";
import ToolTipIssue from "../ToolTip/ToolTipIssue";
import { FlagIcon } from "../../assets/icons/icons";
import { getProductCharacterictic } from "../../utils/getProductCharacterictic";
import { getCurrentColor } from "../../constants";

const ProductItemDesktop = ({ productData, recomendedBorder }) => {
  const {
    product_id,
    price,
    cpr,
    landed_price,
    landed_cpr,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
    title,
    condition,
    vendor_name,
    vendor_rating,
    ship_flag,
    brand_image_w,
    brand_image_b,
    affiliate_url,
    recommend_flag,
    vendor_rating_count,
    gb_flag,
    ship_cost,
    taxes,
    shell_length,
    weapon_category,
  } = productData;

  const characteristics = [
    weapon_category === "Shotgun" && shell_length,
    condition,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
  ];

  const { colorMode } = useColorMode();
  return (
    <ListItem
      border={recommend_flag === "1" ? "2px solid" : "1px solid"}
      borderColor={
        recommend_flag === "1"
          ? "blueC.100"
          : getCurrentColor(colorMode, "grayC.500", "grayC.2400")
      }
      borderRadius={"5px"}
      pt={{ base: "17px", md: recommend_flag === "1" ? 8 : 4 }}
      pb={{ base: 3, md: "16px" }}
      px={5}
      display={{ base: "none", md: "flex" }}
      justifyContent={"space-between"}
      position={"relative"}
      w={"100%"}
      boxShadow={
        "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
      }
      bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
    >
      {recommend_flag === "1" && recomendedBorder()}
      <Box maxW={"140px"} w={"100%"}>
        <Image
          src={getCurrentColor(colorMode, brand_image_w, brand_image_b)}
          mb={"7px"}
          maxW={"125px"}
          objectFit={"scale-down"}
          h={"40px"}
          loading="lazy"
          alt={`${title}`}
        />
        <Text fontWeight={"600"} mb={"5px"} fontFamily={"mainFont"}></Text>
        <Flex flexDirection={"column"}>
          {vendor_name.length > 15 ? (
            <Tooltip
              cursor={"pointer"}
              hasArrow
              label={vendor_name}
              bg="gray.300"
              color="black"
              fontSize={"xs"}
            >
              <Text fontSize={"xs"} mb={"5px"} fontFamily={"mainFont"} as={"b"}>
                {vendor_name.length > 15
                  ? `${vendor_name.slice(0, 14)}...`
                  : vendor_name}
              </Text>
            </Tooltip>
          ) : (
            <Text fontSize={"xs"} mb={"5px"} fontFamily={"mainFont"} as={"b"}>
              {vendor_name}
            </Text>
          )}
        </Flex>
        {gb_flag === 1 && (
          <Flex gap={"5px"} mb={"7px"}>
            <Image
              w={"34px"}
              loading="lazy"
              src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
              alt="gun broker"
            />
            <Text
              fontSize={"xs"}
              color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
              fontFamily={"mainFont"}
            >
              via GunBroker
            </Text>
          </Flex>
        )}
        <Rating rating={vendor_rating} reviews={vendor_rating_count} />
      </Box>
      <Flex flexDirection={"column"} w={"100%"}>
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          gap={{ base: 7, xl: 7 }}
          mb={"12px"}
        >
          <List
            display={"flex"}
            w={"100%"}
            gap={3}
            justifyContent={"space-between"}
          >
            <ListItem maxW={"300px"} w={"100%"}>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Description
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                fontWeight={"600"}
                fontFamily={"mainFont"}
              >
                {title}
              </Heading>
            </ListItem>

            <ListItem minW={"50px"}>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Rounds
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={{ base: "xs", lg: "md" }}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                {rdsperbox}
              </Heading>
            </ListItem>

            <ListItem minW={"90px"}>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Item Cost
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                ${(+cpr).toFixed(2)} CPR
              </Heading>
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"13px"}
                fontWeight={"400"}
                fontFamily={"mainFont"}
              >
                ${+price}
              </Text>
            </ListItem>

            <ListItem minW={"125px"}>
              <Text
                color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                fontSize={"xs"}
                fontWeight={"500"}
                fontFamily={"mainFont"}
              >
                Total Cost
              </Text>
              <Heading
                color={getCurrentColor(colorMode, "blueC.200", "whiteC.100")}
                textDecor={"underline"}
                fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                fontWeight={"700"}
                fontFamily={"mainFont"}
                display={"flex"}
                gap={"6px"}
                alignItems={"center"}
                cursor={"pointer"}
              >
                <ToolTipTotal
                  price={price}
                  totalCost={landed_price}
                  ship_cost={ship_cost}
                  status={ship_flag}
                  taxes={taxes}
                >
                  $
                  {+landed_cpr % 1 === 0
                    ? (+landed_cpr).toFixed(2)
                    : +landed_cpr}{" "}
                  CPR
                  <Box as={"span"} cursor={"pointer"}>
                    {cautionStatus(ship_flag).icon}
                  </Box>
                </ToolTipTotal>
              </Heading>
              <Text
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                fontSize={"13px"}
                fontWeight={"400"}
                fontFamily={"mainFont"}
              >
                ${(+landed_price).toFixed(2)}
              </Text>
            </ListItem>
          </List>
          <Link
            href={`${affiliate_url}`}
            target="_blank"
            px={"18px"}
            py={"10px"}
            bg={"blueC.100"}
            _hover={{ bg: "blueC.200" }}
            color={"whiteC.100"}
            borderRadius={"5px"}
            w={"125px"}
            h={"40px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={{ base: "0px", xl: "auto" }}
          >
            View
          </Link>
        </Flex>
        <Flex gap={"8px"} flexWrap={"wrap"} maxW={"600px"}>
          {getProductCharacterictic(characteristics, colorMode)}
        </Flex>
      </Flex>

      <ToolTipIssue id={product_id}>
        <Box
          cursor={"pointer"}
          border={"1px solid"}
          display={"flex"}
          bottom={"-10px"}
          right={"-10px"}
          justifyContent={"center"}
          alignItems={"center"}
          borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.1600")}
          position={"absolute"}
          borderRadius={"50%"}
          w={"20px"}
          h={"20px"}
        >
          <FlagIcon
            w={"10px"}
            h={"10px"}
            color={getCurrentColor(colorMode, "grayC.2700", "grayC.1600")}
          />
        </Box>
      </ToolTipIssue>
    </ListItem>
  );
};

export default ProductItemDesktop;
