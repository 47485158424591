import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, useColorMode } from "@chakra-ui/react";
import { getCurrentColor } from "../../constants";

const MobileMenuWrapper = ({
  children,
  openStatus,
  title,
  handleChangeStatus,
}) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      w={"100%"}
      position={"absolute"}
      bottom={0}
      left={0}
      bg={"transparent"}
      zIndex={99}
      height={openStatus ? "100vh" : "0"}
      transition={"all 0.3s ease"}
      display={{ base: "flex", lg: "none" }}
      flexDirection={"column"}
      pb={"59px"}
    >
      <Box bg={"blackC.100"} opacity={0.7} w={"100%"} h={"10vh"}></Box>
      <Box
        bg={getCurrentColor(colorMode, "grayC.600", "grayC.1700")}
        w={"100%"}
        h={"90vh"}
        pb={"20px"}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          h={"84px"}
          borderBottom={"1px solid"}
          borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.2400")}
          px={5}
          py={"25px"}
        >
          <Heading
            as="h3"
            fontSize={"28px"}
            fontFamily={"mainFont"}
            fontWeight={600}
            w={"100%"}
          >
            {title}
          </Heading>
          <CloseIcon
            w="24px"
            h="24px"
            onClick={() => handleChangeStatus(false)}
          />
        </Flex>
        <Box
          overflow={"auto"}
          css={{
            "&::-webkit-scrollbar": {
              width: "0px",
            },
          }}
          h={"90%"}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileMenuWrapper;
