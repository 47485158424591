import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { selectedFiltersFromQueryParams } from "../utils/selectedFiltersFromQueryParams";
import useSearchParamsHandler from "./useSearchParamsHandler";
import { filterInfoAtom, selectedFiltersAtom } from "../atom/products";
import { useAtom } from "jotai";

const useSelectedFilter = () => {
  const location = useLocation();
  const [selectedFilters, setSelectedFilters] = useAtom(selectedFiltersAtom);
  const [filterInfo] = useAtom(filterInfoAtom);

  const { params, deleteParam, addOrUpdateParam } = useSearchParamsHandler();

  const queryParamsArr = Object.entries(params);

  const queryString = queryParamsArr.map((item) => {
    return {
      title: item[0],
      value: item[1],
    };
  });

  const { clearParams } = useSearchParamsHandler();

  useEffect(() => {
    setSelectedFilters(selectedFiltersFromQueryParams(queryString, filterInfo));
    // eslint-disable-next-line
  }, [location.search]);

  const selectFilter = (filter) => {
    if (
      selectedFilters[filter.title].some(
        (item) =>
          (item.value + "").toLowerCase() ===
            (filter.flag + "").toLowerCase() ||
          (item.value + "").toLowerCase() ===
            (filter.query + "").toLowerCase() ||
          (item.value + "").toLowerCase() === (filter.value + "").toLowerCase(),
      )
    ) {
      deleteParam(filter.title, filter.flag || filter.value);
    } else {
      addOrUpdateParam(filter.title, filter.flag || filter.value);
    }
  };

  return {
    selectedFilters,
    setSelectedFilters,
    clearParams,
    deleteParam,
    addOrUpdateParam,
    selectFilter,
  };
};

export default useSelectedFilter;
