import {
  Box,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";

const MainPageAboutUs = () => {
  return (
    <Flex
      position={"relative"}
      w={"100%"}
      h={"100%"}
      justifyContent={"flex-end"}
    >
      <Box
        position={"absolute"}
        overflow={"hidden"}
        zIndex={1}
        top={0}
        left={0}
        w={"100%"}
        height={"100%"}
      >
        <Image
          src={
            window.screen.width > 768
              ? "https://cdn.bulletscout.com/image/main-page/main_bg-2.webp"
              : "https://cdn.bulletscout.com/image/main-page/mobil-bg.webp"
          }
          alt="about-us background"
          w={"100%"}
          height={"100%"}
          objectFit={"cover"}
          position={"relative"}
          zIndex={1}
          left={0}
          loading="lazy"
        />
      </Box>
      <Box
        py={{ base: "68px", md: "82px" }}
        pr={{ base: "20px", md: "100px" }}
        pl={{ base: "20px", md: "0px" }}
        position={"relative"}
        zIndex={2}
        maxW={"605px"}
        w={"100%"}
      >
        <Heading
          as={"h2"}
          color={"whiteC.100"}
          fontSize={"3xl"}
          fontWeight={"600"}
          fontFamily={"mainFont"}
          mb={"18px"}
        >
          About Us
        </Heading>
        <Text
          color={"whiteC.100"}
          fontSize={"md"}
          fontWeight={"400"}
          fontFamily={"mainFont"}
          mb={6}
        >
          Welcome to Bullet Scout, your premier destination for finding the
          right ammunition for your firearm needs. With our comprehensive search
          engine, you can explore a wide array of ammunition options, ensuring
          you always hit your mark.
        </Text>
        <Heading
          as={"h3"}
          fontSize={"xl"}
          fontWeight={"600"}
          fontFamily={"mainFont"}
          color={"whiteC.100"}
          mb={4}
        >
          Why choose us?
        </Heading>
        <List listStyleType={""} pl={4}>
          <ListItem
            color={"whiteC.100"}
            fontSize={"md"}
            fontFamily={"mainFont"}
            mb={"6px"}
          >
            <Text as={"b"} mr={2}>
              Extensive Selection:
            </Text>{" "}
            Access a diverse range of ammunition types, calibers, and brands.
          </ListItem>
          <ListItem
            color={"whiteC.100"}
            fontSize={"md"}
            fontFamily={"mainFont"}
            mb={"6px"}
          >
            <Text as={"b"} mr={2}>
              User-Friendly Interface:
            </Text>
            Our intuitive search engine makes finding the perfect ammunition
            quick and effortless.
          </ListItem>
          <ListItem
            color={"whiteC.100"}
            fontSize={"md"}
            fontFamily={"mainFont"}
          >
            <Text as={"b"} mr={2}>
              Expert Guidance:
            </Text>
            Benefit from recommendations and insights to enhance your shooting
            experience.
          </ListItem>
        </List>
      </Box>
    </Flex>
  );
};

export default MainPageAboutUs;
