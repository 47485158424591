import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getCurrentColor } from "../../constants";
import { binarySearch } from "../../utils/binarySearch";

const LoginToAllSite = () => {
  const [showForm, setShowForm] = useState(localStorage.getItem("login"));
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!showForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showForm]);

  const onSubmit = (e) => {
    e.preventDefault();
    const logFromDB = process.env.REACT_APP_LOGIN.split(",");
    const passFromDB = process.env.REACT_APP_PASS.split(",");

    if (
      binarySearch(logFromDB, login) >= 0 &&
      pass === passFromDB[binarySearch(logFromDB, login)]
    ) {
      setShowForm(true);
      localStorage.setItem("login", true);
    } else {
      setError("Wrong login or password");
    }
  };

  return (
    !showForm && (
      <Flex
        position={"fixed"}
        zIndex={200}
        top={0}
        left={0}
        w={"100%"}
        height={"100%"}
        bg={getCurrentColor(colorMode, "whiteC.100", "blueC.500")}
        px={'15px'}
      >
        <Flex m={"auto"} flexDirection={"column"}>
          <Heading mb={"25px"}>Please sign in for beta testing</Heading>
          <form onSubmit={(e) => onSubmit(e)}>
            <FormControl>
              <Input
                placeholder="Login"
                bg={getCurrentColor(colorMode, "whiteC.100", "grayC.2000")}
                border={"0.5px solid"}
                borderColor={getCurrentColor(
                  colorMode,
                  "grayC.500",
                  "grayC.2200",
                )}
                focusBorderColor={"grayC.1300"}
                mb={"18px"}
                id="login"
                value={login}
                name="login"
                onChange={(e) => setLogin(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <InputGroup size="md" mb={"29px"}>
                <Input
                  pr="4.5rem"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  border={"0.5px solid"}
                  bg={getCurrentColor(colorMode, "whiteC.100", "grayC.2000")}
                  borderColor={getCurrentColor(
                    colorMode,
                    "grayC.500",
                    "grayC.2200",
                  )}
                  focusBorderColor={"grayC.1300"}
                  id="password"
                  name="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    bg={"transparent"}
                    border={"none"}
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                    _hover={{ bg: "transparent" }}
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Text
                color={"red.500"}
                fontSize={"12px"}
                position={"absolute"}
                bottom={"-20px"}
                left={0}
              >
                {error}
              </Text>
            </FormControl>

            <Button
              bg={"blueC.400"}
              _hover={{ bg: "blueC.200" }}
              color={"whiteC.100"}
              w={"100%"}
              type="submit"
            >
              Show site
            </Button>
          </form>
        </Flex>
      </Flex>
    )
  );
};

export default LoginToAllSite;
