import { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import useCognitoUserPool from "./useCognitoUserPool";
import { validateNewPassword } from "../utils/validateNewPassword";

const useConfirmPassword = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [tips, setTips] = useState("");
  const { userPool } = useCognitoUserPool();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [codeError, setCodeError] = useState(false);
  const navigate = useNavigate();

  const handleConfirmPassword = (username) => {
    if (verificationCode.length < 5) {
      setCodeError(true);
    } else {
      setCodeError(false);
    }

    if (validateNewPassword(newPassword)) {
      setError(validateNewPassword(newPassword));
      return;
    }

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        setError("");
        setTips("Password confirmed!");
        setTimeout(() => navigate("/login"), 3000);
      },
      onFailure(err) {
        setError(err.message);
      },
    });
  };

  const handleForgotPassword = (username) => {
    if (username.length === 0) {
      setError("Please enter your email");
      return;
    } else {
      setError("");
    }

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        setStep(2);
      },
      onFailure: (err) => {
        setError(err.message);
      },
    });
  };

  return {
    verificationCode,
    newPassword,
    error,
    tips,
    setVerificationCode,
    setNewPassword,
    handleConfirmPassword,
    step,
    handleForgotPassword,
    username,
    setUsername,
    codeError,
  };
};

export default useConfirmPassword;
