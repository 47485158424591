import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import SearchInput from "../SearchInput/SearchInput";

import RangeSliderCustom from "../RangeSlider/RangeSlider";
import useUpdateFiltersData from "../../hooks/useUpdateFiltersData";
import { memo } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import useSelectedFilter from "../../hooks/useSelectedFilter";
import { comparisonCheckedFilter, getCurrentColor } from "../../constants";
import { sortData } from "../../utils/sortData";

const Filter = ({
  checkbox,
  data,
  title,
  slider,
  search,
  ship_flag = false,
  query,
}) => {
  const { colorMode } = useColorMode();

  const {
    filteredData,
    setSearchQuery,
    searchQuery,
    setRangeValues,
    rangeValues,
  } = useUpdateFiltersData(sortData(data), search, slider);

  const { selectedFilters, selectFilter } = useSelectedFilter();
  const Row = ({ index, style }) => {
    const {
      name,
      count,
      icon,
      brand_name,
      vendor_name,
      brand_id,
      vendor_id,
      flag,
      alt_name,
    } = filteredData[index];

    const checkFilter = selectedFilters[query].some((filter) => {
      return (
        filter.title === query &&
        ((filter.value + "").toLowerCase() ===
          comparisonCheckedFilter(alt_name, flag, brand_id, vendor_id, name) ||
          (filter.flag + "").toLowerCase() ===
            comparisonCheckedFilter(alt_name, flag, brand_id, vendor_id, name))
      );
    });

    const selectNewFilter = () => {
      selectFilter({
        title: query,
        value: (
          (alt_name || flag || name || brand_name || vendor_name) + ""
        ).toLowerCase(),
        query: name || brand_name || vendor_name,
        flag:
          alt_name || flag || brand_id || (vendor_id && vendor_id + "") || name,
      });
    };

    return (
      <Checkbox
        sx={{ ".chakra-checkbox__label": { width: "100%" } }}
        display={"flex"}
        w={"100%"}
        key={flag || name || brand_id || vendor_id}
        py={"5px"}
        style={style}
        my={"5px"}
        isChecked={checkFilter}
        onChange={selectNewFilter}
      >
        <Flex
          alignItems={"center"}
          w={"90%"}
          gap={"5px"}
          justifyContent={"space-between"}
        >
          <Flex
            justifyContent={"space-between"}
            w={"100%"}
            alignItems={"center"}
            maxW={icon ? "84px" : "auto"}
          >
            <Text
              fontSize={"xs"}
              textTransform={"capitalize"}
              fontFamily={"mainFont"}
              wordBreak={"break-word"}
              fontWeight={"400"}
            >
              {name || brand_name || vendor_name}
            </Text>
            {icon}
          </Flex>
          <Text fontSize={"13px"} fontFamily={"mainFont"} fontWeight={"400"}>
            {count}
          </Text>
        </Flex>
      </Checkbox>
    );
  };

  return (
    <Flex w={"100%"}>
      <Accordion allowMultiple w={"100%"} defaultIndex={[0]}>
        <AccordionItem
          w={"100%"}
          borderRadius={{ base: "0px", lg: "6px" }}
          border={{ base: "none", lg: "1px solid" }}
          height={"100%"}
          borderColor={getCurrentColor(
            colorMode,
            "grayC.500 !important",
            "transparent !important",
          )}
          _hover={{ bg: getCurrentColor(colorMode, "grayC.600", "grayC.1700") }}
        >
          <AccordionButton
            bg={getCurrentColor(colorMode, "grayC.600", "grayC.1700")}
            borderTopRadius={{ base: "0px", lg: "6px" }}
          >
            <Box
              as="span"
              flex="1"
              color={getCurrentColor(colorMode, "black.200", "grayC.1600")}
              textAlign="left"
            >
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            pt={
              !slider && !search
                ? { base: "10px", md: "10px" }
                : { base: "21px", md: "16px" }
            }
            maxH={"254px"}
            height={"100%"}
            pb={4}
            pr={3}
            w={"100%"}
            overflow={"hidden"}
            h={"100%"}
            bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1900")}
            borderBottomRadius={{ base: "0px", lg: "6px" }}
          >
            {slider && filteredData.length > 0 && (
              <RangeSliderCustom
                data={filteredData}
                updateRange={setRangeValues}
                values={rangeValues}
                title={title}
              />
            )}
            {search && (
              <Box mb={"18px"}>
                <SearchInput
                  title={title}
                  value={searchQuery}
                  handleChange={setSearchQuery}
                  border={"1px solid"}
                  borderColor={getCurrentColor(
                    colorMode,
                    "grayC.500",
                    "grayC.2400",
                  )}
                  display={"block"}
                  pl={"42px"}
                  name={title}
                />
              </Box>
            )}

            <Box minH={filteredData.length < 4 ? "125px" : "154px"} h={"auto"}>
              {checkbox && filteredData.length > 0 ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      width={width}
                      height={height}
                      itemCount={filteredData.length}
                      itemSize={40}
                      itemData={filteredData}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              ) : (
                <Text fontSize={"xs"}>Filter not found</Text>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default memo(Filter);
