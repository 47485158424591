import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import useSelectedFilter from "../../hooks/useSelectedFilter";
import { transformFilterName } from "../../utils/transformFilterName";
import { getCurrentColor } from "../../constants";
import { useLocation } from "react-router-dom";

const SelectedFilters = () => {
  const location = useLocation();
  const { clearParams, selectedFilters, deleteParam } = useSelectedFilter();
  const { colorMode } = useColorMode();

  const filterEntries = Object.entries(selectedFilters);

  return filterEntries.some(([key, values]) => values.length > 0) ? (
    <Box
      borderRadius={"6px"}
      border={"1px solid"}
      borderColor={getCurrentColor(colorMode, "grayC.500", "transparent")}
      mb={"22px"}
    >
      <Heading
        as={"h3"}
        fontSize={"lg"}
        color={getCurrentColor(colorMode, "blackC.200", "grayC.1600")}
        fontFamily={"mainFont"}
        fontWeight={"500"}
        w={"100%"}
        bg={getCurrentColor(colorMode, "grayC.600", "grayC.1700")}
        p={"18px"}
        borderRadius={"6px 6px 0px 0px"}
      >
        Your Selections
      </Heading>
      <Flex
        p={"10px"}
        bg={getCurrentColor(colorMode, "whiteC.500", "grayC.1900")}
        flexDirection={"column"}
        borderTop={"1px solid"}
        borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.1900")}
        borderRadius={"0px 0px 6px 6px"}
      >
        {filterEntries.map(([key, values]) => {
          if (values.length === 0) return null;
          const data = transformFilterName(location.pathname, key, {
            title: key,
            value: values.map((item) => item.value),
            flag: values.map((item) => item.flag || item.value),
          });

          return (
            <Flex
              key={key + toString(values)}
              fontSize={"sm"}
              fontWeight={"600"}
              pr={3}
              position={"relative"}
              textTransform={"capitalize"}
              gap={"5px"}
              flexDirection={"column"}
            >
              <Text fontSize={"md"} fontWeight={"600"}>
                {data.title}:
              </Text>
              <List pl={2}>
                {data.value.map((item, i) => {
                  return (
                    <ListItem
                      key={item}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Flex
                        gap={"3px"}
                        fontSize={"sm"}
                        fontWeight={"400"}
                        display={"inline"}
                        flexWrap={"wrap"}
                        textTransform={"capitalize"}
                        wordBreak={"break-word"}
                      >
                        - {item}
                      </Flex>
                      <SmallCloseIcon
                        onClick={() => {
                          deleteParam(data.query, data.flag[i] || item);
                        }}
                        cursor={"pointer"}
                        right={0}
                        top={"4px"}
                        color={getCurrentColor(
                          colorMode,
                          "blackC.200",
                          "grayC.1600",
                        )}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Flex>
          );
        })}
        <Button
          p={0}
          display={"flex"}
          mt={"17px"}
          justifyContent={"flex-start"}
          variant={"underline"}
          onClick={() => clearParams()}
          color={getCurrentColor(colorMode, "blackC.200", "grayC.1600")}
          textDecoration={"underline"}
          _hover={{ textDecoration: "none" }}
        >
          Clear All
        </Button>
      </Flex>
    </Box>
  ) : null;
};

export default SelectedFilters;
