import { Box, Select, Text, useColorMode } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { getCurrentColor } from "../../constants";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";
import { sortByAtom } from "../../atom/products";
import { useAtom } from "jotai";

const SelectSort = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { colorMode } = useColorMode();
  const { params } = useSearchParamsHandler();
  const [sortBy, setSortBy] = useAtom(sortByAtom);

  useEffect(() => {
    if (searchParams.get("sort_by") !== sortBy) {
      setSearchParams({ ...params, sort_by: sortBy });
    }
    // eslint-disable-next-line
  }, [sortBy]);

  useEffect(() => {
    if (searchParams.get("sort_by") !== sortBy) {
      setSortBy(searchParams.get("sort_by"))
    }
   // eslint-disable-next-line
  }, [searchParams.get("sort_by")])

  return (
    <Box
      position={"relative"}
      border={"1px solid"}
      borderColor={"blackC.600"}
      borderRadius={"6px"}
      py={{ base: "0px", md: "8px" }}
      bg={"transparent"}
      cursor={"pointer"}
    >
      <Text
        as={"span"}
        position={"absolute"}
        fontSize={"xs"}
        fontFamily={"mainFont"}
        color={getCurrentColor(colorMode, "grayC.300", "whiteC.100")}
        left={"14px"}
        display={{ base: "none", md: "block" }}
        cursor={"pointer"}
      >
        Sort by
      </Text>

      <ChevronDownIcon
        color={"grayC.200"}
        position={"absolute"}
        top={"50%"}
        transform={"translateY(-50%)"}
        right={"14px"}
        display={{ base: "none", md: "block" }}
        w={"20px"}
        h={"20px"}
        cursor={"pointer"}
      />
      <Select
        cursor={"pointer"}
        border={"none"}
        _focus={{ outline: "none" }}
        px={0}
        p={0}
        pr={"20px"}
        maxW={"190px"}
        w={"100%"}
        position={"relative"}
        top={"7px"}
        bg={"transparent"}
        fontSize={{ base: "10", "1xs": "sm" }}
        display={{ base: "none", md: "block" }}
        variant={"withoutIcon"}
        icon={"none"}
        onChange={(e) => setSortBy(e.target.value)}
        value={sortBy}
      >
        <option value="landed_cpr">Total CPR</option>
        <option value="cpr">Item CPR</option>
      </Select>

      <Select
        border={"none"}
        _focus={{ outline: "none" }}
        px={0}
        p={0}
        maxW={"190px"}
        w={"100%"}
        position={"relative"}
        bg={"transparent"}
        fontSize={{ base: "10", "1xs": "sm" }}
        display={{ base: "block", md: "none" }}
        placeholder="Sort by"
      >
        <option value="landed_cpr">Total CPR</option>
        <option value="cpr">Item CPR</option>
      </Select>
    </Box>
  );
};

export default SelectSort;
