import { Box, Flex, Heading, useColorMode } from "@chakra-ui/react";
import { lazy, memo, Suspense } from "react";
import { useAtom } from "jotai";

import ShipTo from "./ShipTo";
import FilterSkeletonDesktop from "../../skeletons/Filters/FilterSkeletonDesktop";

import { getCurrentColor } from "../../constants";

import { filtersLoadingStatusAtom } from "../../atom/products";

const SelectedFilters = lazy(() => import("./SelectedFilters"));
const QuickNavigate = lazy(() => import("./QuickNavigate"));
const FiltersWrapper = lazy(() => import("./FiltersWrapper"));

const FiltersDesktop = () => {
  const [filtersLoadingStatus] = useAtom(filtersLoadingStatusAtom);
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexDirection={"column"}
      display={{ base: "none", lg: "block" }}
      maxW={"200px"}
      w={"100%"}
    >
      <ShipTo />
      {filtersLoadingStatus === "loading" ? (
        <FilterSkeletonDesktop />
      ) : (
        <Suspense fallback={<FilterSkeletonDesktop />}>
          <SelectedFilters />
          <Box
            pb={5}
            borderBottom={"1px solid"}
            borderColor={getCurrentColor(colorMode, "grayC.1200", "grayC.2400")}
            mb={4}
          >
            <QuickNavigate />
          </Box>
          <Box>
            <Heading
              as={"h3"}
              fontSize={"lg"}
              color={getCurrentColor(colorMode, "blackC.200", "whiteC.100")}
              fontFamily={"mainFont"}
              fontWeight={"500"}
              mb={4}
            >
              Filter by
            </Heading>
            <Flex flexDirection={"column"} gap={"10px"}>
              <FiltersWrapper />
            </Flex>
          </Box>
        </Suspense>
      )}
    </Flex>
  );
};

export default memo(FiltersDesktop);
