import {
  Button,
  Flex,
  Image,
  List,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import ProductItem from "../components/ProductItem/ProductItem";
import Pagination from "../components/Pagination/Pagination";
import ProductSkeletonDesctop from "../skeletons/Products/ProductSkeletonDesktop";
import ProductSkeletonMobile from "../skeletons/Products/ProductSkeletonMobile";

import { SearchIcon } from "@chakra-ui/icons";
import useSearchProduct from "../hooks/useSearchProduct";
import SearchInput from "../components/SearchInput/SearchInput";
import { useState } from "react";
import { getCurrentColor } from "../constants";
import { Helmet } from "react-helmet";

const SearchPage = () => {
  const [error, setError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const [search, setSearch] = useState(
    location.search.split("&")[0].split("=")[1] || "",
  );

  const {
    currentPage,
    allPages,
    products,

    setCurrentPage,
    loading,
  } = useSearchProduct();

  const handleChange = (e) => {
    if (search.length < 1) {
      setError("Please enter at least 2 characters");
    } else {
      setError("");
      navigate(`/search?q=${search}`);
    }
  };

  const renderProductsSkeleton = () => (
    <Flex
      flexDirection={{ base: "row", xl: "column" }}
      flexWrap={"wrap"}
      gap={"10px"}
      justifyContent={"space-around"}
    >
      {[...Array(30)].map((item, index) =>
        window.screen.width > 1150 ? (
          <ProductSkeletonDesctop key={index} />
        ) : (
          <ProductSkeletonMobile key={index} />
        ),
      )}
    </Flex>
  );

  const renderProducts = (
    <List
      mb={{ base: "58px", md: "52px" }}
      w={"100%"}
      display={"flex"}
      flexDirection={{ base: "row", xl: "column" }}
      justifyContent={{ base: "space-around", xl: "flex-start" }}
      flexWrap={"wrap"}
      gap={5}
      ml={"auto"}
    >
      {products.map((product) => (
        <ProductItem key={product.listing_id} product={product} />
      ))}
    </List>
  );

  const renderPagination = (
    <Pagination
      currentPage={+currentPage}
      allPages={+allPages}
      changePage={setCurrentPage}
    />
  );

  const renderNotFound = (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <Text>No products were found for these filters</Text>
      <Image
        w={"70%"}
        src={
          colorMode === "light"
            ? "https://cdn.bulletscout.com/image/not-found.png"
            : "https://cdn.bulletscout.com/image/not-found-dark1.png"
        }
        alt="not found"
      />
    </Flex>
  );

  return (
    <Flex
      maxW={"1240px"}
      w={"100%"}
      mx={"auto"}
      pt={{ base: "22px", lg: "45px" }}
      flexDirection={"column"}
      mb={{ base: "72px", md: "64px" }}
      px={{ base: "22px", lg: "10px" }}
    >
      <Helmet>
        <title>Sing to Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <Flex
        px={3}
        py={2.5}
        gap={{ base: "5px", md: "12px" }}
        w={"100%"}
        borderRadius={"8px"}
        position={"relative"}
        mb={"25px"}
      >
        <SearchInput
          value={search}
          handleChange={(e) => {
            setSearch(e);
            setError("");
          }}
          display={{ base: "none", md: "block" }}
          bg={getCurrentColor(colorMode, "grayC.600", "grayC.1700")}
        />

        <Button
          bg={"blueC.100"}
          color={"whiteC.100"}
          py={3}
          w={"148px"}
          _hover={{
            bg: "blueC.200",
          }}
          display={{ base: "none", md: "block" }}
          onClick={handleChange}
        >
          Search
        </Button>
        <Button
          bg={"blueC.100"}
          color={"whiteC.100"}
          p={0}
          w={{ base: "44px", md: "148px" }}
          _hover={{
            bg: "blueC.200",
          }}
          display={{ base: "block", md: "none" }}
        >
          <SearchIcon color={"whiteC.100"} />
        </Button>
        <Text
          position={"absolute"}
          left={"50px"}
          color={"red.500"}
          bottom={"-25px"}
        >
          {error}
        </Text>
      </Flex>

      <Flex justifyContent={"space-between"} w={"100%"} gap={"20px"}>
        <Flex flexDirection={"column"} w={"100%"}>
          {loading ? (
            renderProductsSkeleton()
          ) : (
            <>
              {products.length > 0 ? renderProducts : renderNotFound}
              {products.length > 0 &&
                currentPage &&
                allPages &&
                renderPagination}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SearchPage;
