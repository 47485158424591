import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const useUpdateFiltersData = (filtersData, search, slider) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [rangeValues, setRangeValues] = useState([]);
  const location = useLocation();

  const filteredData = useMemo(() => {
    let updatedData = [...filtersData];

    if (search && searchQuery.trim() !== "") {
      const lowercaseSearchQuery = searchQuery.toLowerCase();
      updatedData = updatedData.filter((item) =>
        ["name", "brand_name", "vendor_name"].some((key) =>
          (item[key] || "").toLowerCase().includes(lowercaseSearchQuery),
        ),
      );
    }

    if (
      slider &&
      rangeValues.length === 2 &&
      rangeValues.every((v) => v !== undefined)
    ) {
      updatedData = updatedData.filter(
        (item) =>
          (item.alt_name >= +rangeValues[0] &&
            item.alt_name <= +rangeValues[1]) ||
          (+item.name >= +rangeValues[0] && +item.name <= +rangeValues[1]),
      );
    }

    return updatedData;
  }, [filtersData, search, searchQuery, slider, rangeValues]);

  useEffect(() => {
    if (location.pathname !== "/initial-path") {
      setSearchQuery("");
      setRangeValues([]);
    }
  }, [location.pathname]);

  return {
    filteredData,
    setSearchQuery,
    searchQuery,
    setRangeValues,
    rangeValues,
  };
};

export default useUpdateFiltersData;
