export const transformDataWithId = (
  filterInfo,
  filtersData,
  data,
  value,
  title,
  flag,
) => {
  const map = {};

  filtersData.forEach((item) => {
    const values = item.value.split(",").map((val) => val.trim());
    values.forEach((val) => {
      if (!map[title]) {
        map[title] = [];
      }
      map[title].push({ title: item.title, value: val });
    });
  });

  const result =
    filterInfo &&
    filterInfo[data]?.filter((item) => {
      const currentValue = item[flag || value] + "";
      map[title]?.some((entry) => {
        return entry.value === currentValue;
      });
      return map[title]?.some((entry) => entry.value === currentValue);
    });

  if (!result) return [];
  return result.map((item) => ({
    value: item[value],
    title: title,
    flag: item[flag],
  }));
};
