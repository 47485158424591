export const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const passwordValidate = {
  hasUpperCase: (value) =>
    /[A-Z]/.test(value) ||
    "Password must contain at least one uppercase letter",
  hasLowerCase: (value) =>
    /[a-z]/.test(value) ||
    "Password must contain at least one lowercase letter",
  hasNumber: (value) =>
    /\d/.test(value) || "Password must contain at least one number",
  hasSpecialChar: (value) =>
    /[\W_]/.test(value) ||
    "Password must contain at least one special character",
};
export const getCurrentColor = (theme, black, white) => {
  return theme === "dark" ? white : black;
};
export const comparisonCheckedFilter = (
  alt_name,
  flag,
  brand_id,
  vendor_id,
  name,
) => ((alt_name || flag || brand_id || vendor_id || name) + "").toLowerCase();
export const headerLink = (category, name, cookie) =>
  // eslint-disable-next-line
  `/${category.replace("/(?:s|-)?ammo/gi,", "").toLowerCase()}/${encodeURIComponent(name && name.replace("/", " "))}?wc=${category.replace(/ Ammo|-ammo|ammo/g, "").toLowerCase()}&caliber=${encodeURIComponent(name)}&page=1&sort_by=landed_cpr${cookie ? `&zip=${cookie}` : ""}`;
export const calibersLink = (path, caliber, categoryName, cookie) =>
  `${path}/${encodeURIComponent(caliber && caliber.replace("/", " "))}?wc=${categoryName.replace(/ Ammo|-ammo|ammo/g, "").toLowerCase()}&caliber=${encodeURI(caliber.replace("&", " "))}&page=1&sort_by=landed_cpr${cookie ? `&zip=${cookie}` : ""}`;

export const offsetTopStatus = (value) => value < 250;
