import { Flex, Image, List, Text, useColorMode } from "@chakra-ui/react";
import ProductSkeletonDesctop from "../../skeletons/Products/ProductSkeletonDesktop";
import ProductSkeletonMobile from "../../skeletons/Products/ProductSkeletonMobile";
import useUpdateProducts from "../../hooks/useUpdateProducts";
import Pagination from "../Pagination/Pagination";
import { lazy, Suspense } from "react";

const ProductItem = lazy(() => import("../ProductItem/ProductItem"));

const ProductsList = () => {
  const { colorMode } = useColorMode();
  const { currentPage, allPages, productsInfo, productsLoadingStatus } =
    useUpdateProducts();

  const renderProductsSkeleton = () => (
    <Flex
      flexDirection={{ base: "row", xl: "column" }}
      flexWrap={"wrap"}
      gap={"3px"}
      justifyContent={"space-around"}
    >
      {[...Array(30)].map((item, index) =>
        window.screen.width > 768 ? (
          <ProductSkeletonDesctop key={index} />
        ) : (
          <ProductSkeletonMobile key={index} />
        ),
      )}
    </Flex>
  );

  const renderNotFound = (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <Text>No products were found for these filters</Text>
      <Image
        alt="Not found"
        w={"70%"}
        src={
          colorMode === "light"
            ? "https://cdn.bulletscout.com/image/not-found.png"
            : "https://cdn.bulletscout.com/image/not-found-dark1.png"
        }
      />
    </Flex>
  );

  return (
    <Flex flexDirection={"column"} w={"100%"}>
      {productsLoadingStatus === "loading" ? renderProductsSkeleton() : null}

      {productsLoadingStatus !== "loading" &&
        productsInfo &&
        productsInfo.length <= 0 &&
        renderNotFound}

      <Suspense fallback={renderProductsSkeleton()}>
        <List
          mb={{ base: "58px", md: "52px" }}
          maxWidth={"1015px"}
          w={"100%"}
          display={"flex"}
          flexDirection={{ base: "row", xl: "column" }}
          justifyContent={{ base: "space-around", xl: "flex-start" }}
          flexWrap={"wrap"}
          gap={3}
          ml={"auto"}
        >
          {productsInfo &&
            productsInfo.map((product) => (
              <ProductItem key={product.listing_id} product={product} />
            ))}
        </List>
      </Suspense>
      <>
        {productsInfo && productsInfo.length > 0 && currentPage && allPages && (
          <Pagination />
        )}
      </>
    </Flex>
  );
};

export default ProductsList;
