import { useEffect, useMemo, useState } from "react";

import { useAtom } from "jotai";
import {
  allCategoryInfoAtom,
  calibersLoadingStatusAtom,
  fetchCalibers,
} from "../atom/calibers";

const useUpdateQuickNavigate = (search) => {
  const [allCategoryInfo] = useAtom(allCategoryInfoAtom);
  const [calibersLoading] = useAtom(calibersLoadingStatusAtom);

  const [, setCalibers] = useAtom(fetchCalibers);
  const [filteredCalibers, setFilteredCalibers] = useState([]);

  useEffect(() => {
    if (allCategoryInfo.length === 0) {
      setCalibers();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredCalibers(allCategoryInfo);
  }, [allCategoryInfo]);

  useEffect(() => {
    if (search.length === 0) {
      setFilteredCalibers(allCategoryInfo);
    } else {
      const filteredData = allCategoryInfo.map((item) => ({
        ...item,
        calibers: item.calibers.filter((caliber) =>
          caliber.caliber.toLowerCase().includes(search.toLowerCase()),
        ),
      }));

      setFilteredCalibers(filteredData);
    }
    // eslint-disable-next-line
  }, [search]);

  const memoizedValues = useMemo(() => {
    return {
      filteredCalibers,
      calibersLoading,
    };
  }, [filteredCalibers, calibersLoading]);

  return memoizedValues;
};

export default useUpdateQuickNavigate;
