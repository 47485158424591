export const getTitle = (location) => {
  switch (decodeURIComponent(location)) {
    case "/handgun-ammo":
      return "Handgun Ammo";
    case "/rifle-ammo":
      return "Rifle Ammo";
    case "/rimfire-ammo":
      return "Rimfire Ammo";
    case "/shotgun-ammo":
      return "Shotgun Ammo";
    default:
      return "Handgun Ammo";
  }
};
