import { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import useCognitoUserPool from "./useCognitoUserPool";

const useConfirmRegistration = () => {
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const { userPool } = useCognitoUserPool();

  const confirmRegistration = (username, confirmationCode) => {
    if (confirmationCode.length < 4) {
      setCodeError(true);
      return;
    }

    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        setCodeError(true);
        return;
      }
      setConfirmEmail(true);
    });
  };

  return { confirmEmail, codeError, confirmRegistration };
};

export default useConfirmRegistration;
